<template>
    <div>
      <form v-on:submit.prevent= "edit_company();">
        <div class="space-y-12 sm:space-y-16">
          <div>
            <h1 class="text-center text-2xl font-semibold leading-7 text-gray-900">Editar Proyecto </h1>
            <p class="w-3/5 m-auto my-8 p-1 text-left text-sm leading-1 text-gray-600">Modifica los datos del proyecto.</p>
            <div class="container w-3/5 m-auto p-3 rounded space-y-10">

              <div class="relative w-full">
                <label for="name" class="absolute font-bold -top-2 left-2 inline-block bg-white px-1 text-xs text-gray-400">Nombre del proyecto</label>
                <input type="text" id="name_company"  name="name_company" autocomplete="name_company" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" require v-model="project.nombre"/>
                <span class="block bg-white w-full focus:outline-none focus:shadow-outline border border-red-500 rounded-md py-1 px-1 appearance-none leading-normal" v-if="submited && !$v.project.nombre.required">El campo nombre es obligatorio.</span>
              </div>

              <div class="relative w-full">
                <label for="name" class="absolute font-bold -top-2 left-2 inline-block bg-white px-1 text-xs text-gray-400">Seleccione una Empresa</label>
                  <select id="company" name="company" autocomplete="company-name"  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"  v-model="project.empresaId">
                    <option v-for="item in list_companies" :value= "item.id">{{ item.name }}</option>
                  </select>
              </div>

              <div class="relative float-label-input">
                <button type="button" class="text-sm font-semibold leading-6 text-gray-900 px-1.5 py-1.5 rounded-md border border-gray-950" style="margin: 1em;"><router-link to="/projects">Cancelar</router-link><router-view /></button>
                <button type="submit" class="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" style="margin: 1em;">Guardar cambios</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </template>
  
  <script>
  import CompaniesServices from '@/services/companies/companies'
  import ProjectsServices from '@/services/projects/projects'
  import { required } from '@vuelidate/validators';
  import useVuelidate from '@vuelidate/core';
  export default {
    name: 'EditProject',
    data() {
      return {
        submited: false,
        list_companies: [],
        project: {
          id: '',
          nombre: '',
          empresaId: '',
          empresaName: '',
        },
        id_recibido : '',
      }
    },
    mounted() {
      this.id_recibido = this.$route.params.id;
      this.listar_empresas()
      ProjectsServices.infoProyecto({
        options:'info',
        id: this.id_recibido, 
      })
      .then(response => {
        this.project.id = response.data.id
        this.project.nombre = response.data.name
        this.project.empresaId = response.data.id_company
        this.project.empresaName = response.data.company
      })
    },
    setup() {
      return { v$: useVuelidate() };
    },
    methods: {
      listar_empresas () {
        CompaniesServices.empresasListado({
            options:'list_total', 
          }).then(response => {
          this.list_companies =  response.data.listado
        })
      },
      edit_company () {
        this.v$.$touch();
        if (this.v$.$invalid) {
          console.log('Formulario no válido');
          return;
        }
        ProjectsServices.editarProyecto({
          options:'update',
          id: this.id_recibido,
          name : this.project.nombre,
          id_compania : this.project.empresaId,
        })
        .then(response => {
          this.$swal({
            title: "¡Buen trabajo!",
            text: response.data.message,
            icon: "success",});
          this.$router.push("/projects");
        })
      }
    },
    validations: {
      project: {
        nombre:{
          required
        },
        empresaId:{
          required
        }
      },
    },
    props: {
      msg: String
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>

  .float-label-input {
    margin: 32px 0;     
  }

  .float-label-input:focus-within label, .float-label-input input:not(:placeholder-shown) + label {
    transform: translateY(-1.5rem) scale(0.75);
    background-color: white;
  }

  .float-label-input select:not(:placeholder-shown) + label {
    transform: translateY(-1.5rem) scale(0.75);
    background-color: white;
  }
  </style>
  