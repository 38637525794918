export default function () {
    return {
        hash_id: null,
        username: null,
        profile: null,
        is_admin: false,
        permissions: [],
        type: null,
        id: null
    }
}
