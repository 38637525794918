<template>
    <div>
      <form v-on:submit.prevent= "create_company();">
        <div class="space-y-12 sm:space-y-16">
          <div>
            <h1 class="text-center text-2xl font-semibold leading-7 text-gray-900">Crear empresa </h1>
            <p class="w-3/5 my-8 m-auto p-1 text-left text-sm leading-1 text-gray-600">Registra los datos de la empresa.</p>
            <div class="container w-3/5 m-auto p-3 rounded">
              <div class="relative float-label-input m-8">
                  <input type="text" id="nombre" name="nombre" autocomplete="nombre" placeholder="" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="company.nombre" />
                  <label for="nombre" class="absolute top-3 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white px-2 text-grey-darker">Nombre</label>
                  <span class="block bg-white w-full focus:outline-none focus:shadow-outline border border-red-500 rounded-md py-1 px-1 appearance-none leading-normal" v-if="submited && !$v.company.nombre.required">El campo nombre es obligatorio.</span>
              </div>
              <!-- <div class="w-72">
                <div class="relative h-10 w-full min-w-[200px]">
                  <input
                    class="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                    placeholder=" "
                  />
                  <label class="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                    Required
                  </label>
                </div>
              </div> -->
              

              <div class="relative float-label-input" >
                  <input type="text" id="rfc"  name="rfc" autocomplete="rfc" placeholder="" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="company.rfc" />
                  <label for="rfc" class="absolute top-3 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white px-2 text-grey-darker">RFC</label>
                  <span class="block bg-white w-full focus:outline-none focus:shadow-outline border border-red-500 rounded-md py-1 px-1 appearance-none leading-normal" v-if="submited && !$v.company.rfc.required">Inserte el RFC.</span>
              </div>

              <div class="relative float-label-input">
                <textarea id="direccion" name="direccion" rows="3" autocomplete="direccion" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="" v-model="company.direccion"></textarea>
                <label for="direccion" class="absolute top-3 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white px-2 text-grey-darker">Dirección</label>
                <span class="block bg-white w-full focus:outline-none focus:shadow-outline border border-red-500 rounded-md py-1 px-1 appearance-none leading-normal" v-if="submited && !$v.company.direccion.required">Inserte la dirección de la empresa.</span>
              </div>

              <div class="relative float-label-input">
                <button type="button" class="text-sm font-semibold leading-6 text-gray-900 px-1.5 py-1.5 rounded-md border border-gray-950" style="margin: 1em;"><router-link to="/companies">Cancelar</router-link><router-view /></button>
                <button type="submit" class="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" style="margin: 1em;">Agregar Empresa</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </template>
  
  <script>
  import CompaniesServices from '@/services/companies/companies'
  import { required } from '@vuelidate/validators';
  import useVuelidate from '@vuelidate/core';
  export default {
    name: 'CreateCompany',
    data() {
      return {
        submited: false,
        company: {
          nombre: '',
          rfc: '',
          direccion: ''
        },
      }
    },
    setup() {
      return { v$: useVuelidate() };
    },
    methods: {
      create_company () {
        this.v$.$touch();
        if (this.v$.$invalid) {
          console.log('Formulario no válido');
          return;
        }
        CompaniesServices.crearEmpresa({
          options:'new',
          name : this.company.nombre,
          rfc : this.company.rfc,          
          address : this.company.direccion
      })
      .then(response => {
        this.$swal({
           title: "¡Buen trabajo!",
           text: response.data.message,
           icon: "success",});
        this.$router.push("/companies");
      })
      
    }
    },
    validations: {
      company: {
        nombre:{required},
        rfc: {required},
        direccion:{required}
      },
    },
    props: {
      msg: String
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: black;
  }

  .float-label-input {
    margin: 32px 0;     
  }

  .float-label-input:focus-within label, .float-label-input input:not(:placeholder-shown) + label {
    transform: translateY(-1.5rem) scale(0.75);
    background-color: white;
  }

 .float-label-input textarea:not(:placeholder-shown) + label {
    transform: translateY(-1.5rem) scale(0.75);
    background-color: white;
  }
  </style>
  