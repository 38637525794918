import { axiosInstance } from '@/boot/axios';

export default {
    crearAdjunto (data) {
        return axiosInstance.post('/adjunt_task/crud', data);
    },
    adjuntosListado(data) {
        return axiosInstance.post('/adjunt_task/crud', data);
    },
    eliminarAdjunto(data) {
        return axiosInstance.post('/adjunt_task/crud', data);
    },
    infoAdjunto(data) {
        return axiosInstance.post('/adjunt_task/crud', data);
    },
    editarAdjunto(data) {
        return axiosInstance.post('/adjunt_task/crud', data);
    },
    descargarAdjunto(data) {
        return axiosInstance.post('/adjunt_task/crud', data);
    },
}