<template>
    <div>
      <form v-on:submit.prevent= "edit_task();">
        <div class="space-y-12 sm:space-y-16">
          <div>
            <h1 class="text-center text-2xl font-semibold leading-7 text-gray-900">Editar Tarea </h1>
            
            <div class="container w-3/5 m-auto p-3 rounded space-y-10">
              
              <p class="w-full p-1 text-left text-lm leading-1 text-gray-600">Tarea correspondiente al proyecto {{ name_proyecto }}.</p>
              <div class="relative w-full">
                <label for="title" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs text-gray-500">Título</label>
                <input type="text" id="title" name="title" autocomplete="title" placeholder="" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="task.titulo" />
                 <span class="block bg-white w-full focus:outline-none focus:shadow-outline border border-red-500 rounded-md py-1 px-1 appearance-none leading-normal" v-if="submited && !$v.task.titulo.required">Agregue un título a la tarea.</span>
              </div>

              <div class="relative w-full">
                <textarea id="description" name="description" rows="3" autocomplete="description" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="" v-model= "task.descripcion"></textarea>
                <label for="description" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs text-gray-500">Descripción</label>
                <span class="block bg-white w-full focus:outline-none focus:shadow-outline border border-red-500 rounded-md py-1 px-1 appearance-none leading-normal" v-if="submited && !$v.task.descripcion.required">Inserte la descripción de la tarea a realizar.</span>
              </div>

              <div class="relative w-full">
                <select id="type" name="type" autocomplete="type-task" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="task.tipo">
                  <option value= "1">Error</option>
                  <option value= "2">Adición </option>
                </select>
                <label for="type" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs text-gray-500">Seleccione el tipo de tarea</label>
                <span class="block bg-white w-full focus:outline-none focus:shadow-outline border border-red-500 rounded-md py-1 px-1 appearance-none leading-normal" v-if="submited && !$v.task.tipo.required">Seleccione el tipo de tarea. </span>
              </div>

              <div class="relative w-full">
                <select id="type" name="type" autocomplete="type-task" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="task.estatus">
                  <option value= "1">Pendiente</option>
                  <option value= "2">En revisión </option>
                  <option value= "3">En desarrollo</option>
                  <option value= "4">En pruebas </option>
                  <option value= "5">Terminado</option>
                </select>
                <label for="type" class="absolute -top-2 left-2 inline-block bg-white px-1 text-xs text-gray-500">Seleccione el estatus de tarea</label>
                <span class="block bg-white w-full focus:outline-none focus:shadow-outline border border-red-500 rounded-md py-1 px-1 appearance-none leading-normal" v-if="submited && !$v.task.estatus.required">Seleccione el estatus de tarea. </span>
              </div>

              <div class="relative float-label-input">
                <button type="button" class="text-sm font-semibold leading-6 text-gray-900 px-1.5 py-1.5 rounded-md border border-gray-950" style="margin: 1em;" @click="selected_task();">Cancelar</button>
                <button type="submit" class="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" style="margin: 1em;">Guardar cambios</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </template>
  
  <script>
  import TasksServices from '@/services/tasks/tasks'
  import { required, minLength } from '@vuelidate/validators';
  export default {
    name: 'EditTask',
    data() {
      return {
        submited: false,
        task: {
          titulo: '',
          descripcion: '',
          tipo: '',
          tiempo_desarollo: '',
          estatus: ''
        },
        id_tarea : 0,
        id_proyecto:0,
        name_proyecto: '',
      }
    },
    mounted() {
        this.id_tarea = this.$route.params.id;
        this.id_proyecto = this.$route.params.id_project;
        TasksServices.infoTarea({
          options:'info',
          id: this.id_tarea, 
        })
        .then(response => {
        this.task.titulo = response.data.title
        this.task.descripcion = response.data.description
        this.task.tipo = response.data.type
        this.task.estatus = response.data.task_status
        this.name_proyecto = response.data.project   
        })
      },
    methods: {
      selected_task () {
        this.$router.push({
          name: "TareaProyecto",
          params:{
            id: this.id_proyecto,
          }
        });
      },
      edit_task () {
        if(this.$v.$invalid){
          this.submited = true;
          return false;
        }
      TasksServices.editarTarea({
        options:'update',
        id_task: this.id_tarea,
        title : this.task.titulo,
        description : this.task.descripcion,          
        type : this.task.tipo,
        status : this.task.estatus
      })
      .then(response => {
        this.$swal({
           title: "¡Buen trabajo!",
           text: response.data.message,
           icon: "success",});
        this.selected_task()
      })
    }
    },
    validations: {
      task: {
        titulo:{
          required,
          minLength: minLength(2)
        },
        descripcion: {
          required,
          minLength: minLength(2)
        },
        tipo:{
          required,
        },
        estatus:{
          required,
        }
      },
    },
    props: {
      msg: String
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: black;
  }

  .float-label-input {
    margin: 32px 0;     
  }

  .float-label-input:focus-within label, .float-label-input input:not(:placeholder-shown) + label {
    transform: translateY(-1.5rem) scale(0.75);
    background-color: white;
  }
  </style>
  