<template>
    <div class="flex flex-col items-center space-y-10 ">
        <h1 class="font-bold text-3xl">Crear Nuevo Administrador</h1>
        <h2 class="font-semibold">Registra los datos del administrador</h2>
        <div class="relative w-2/5">
    <label for="name" class="absolute font-bold -top-2 left-2 inline-block bg-white px-1 text-xs text-gray-900">Nombre(s)</label>
    <input type="text" name="nombre(s)" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" require v-model="administrador.nombre "/>
  </div>
  <div class="relative w-2/5">
    <label for="name" class="absolute font-bold -top-2 left-2 inline-block bg-white px-1 text-xs text-gray-900">Apellido(s)</label>
    <input type="text" name="apellido(s)" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="administrador.apellido"/>
  </div>
  <div class="relative w-2/5">
    <label for="email" class="absolute font-bold -top-2 left-2 inline-block bg-white px-1 text-xs text-gray-900">Correo</label>
      <input type="mail" name="email" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="administrador.correo" placeholder=""/>
  </div>
  <div class="flex flex-row items-center justify-center space-x-4 w-full">
    <div class="relative w-1/5">
    <label for="password" class="absolute font-bold -top-2 left-2 inline-block bg-white px-1 text-xs text-gray-900">Contraseña</label>
      <input type="password" name="password" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="administrador.contrasena" placeholder="8 caracteres mínimo"/>
  </div>
  <div class="relative w-1/5">
    <label for="password" class="absolute font-bold -top-2 left-2 inline-block bg-white px-1 text-xs text-gray-900">Repetir Contraseña</label>
      <input type="password" name="password2" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="administrador.repeatcontrasena" placeholder="8 caracteres m+inimo"/>
  </div>
  </div>
  <div class="relative float-label-input">
    <button type="button" class="text-sm font-semibold leading-6 text-gray-900 px-1.5 py-1.5 rounded-md border border-gray-950" style="margin: 1em;"><router-link to="/administradores">Cancelar</router-link><router-view /></button>
    <button type="submit" @click="guardarusuario" class="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" style="margin: 1em;">Guardar cambios</button>
    </div>
  </div>
</template>

<script>
import administradorServices from '@/services/Administradores/Administradores'

export default {
  name: "CrearAdministrador",
  data ()
    {
      return {
        administrador: {
          nombre: "",
          apellido: "",
          correo: "",
          contrasena: "",
          repeatcontrasena: "",
        }
      }
    },
    methods: {
      guardarusuario () {
        if (this.administrador.nombre === '' && this.administrador.apellido === '' && this.administrador.correo === '' && this.administrador.contrasena === '' && this.administrador.repeatcontrasena === '') {
          this.$swal(
              'Ups',
              'Es necesario llenar los campos',
              'warning'
          ) 
          return  
        }
        if (this.administrador.nombre === '' || this.administrador.nombre === null) {
          this.$swal(
              'Ups',
              'Ingresa el nombre completo',
              'warning'
          ) 
          return  
        }
          if (this.administrador.apellido === '' || this.administrador.apellido == null) {
          this.$swal(
              'Ups',
              'Ingresa los apellidos',
              'warning'
          )
          return
        }
        if (this.administrador.correo === '' || this.administrador.correo == null) {
          this.$swal(
            'Ups',
            'Ingresa un correo',
            'warning'
          )
          return
        }
        if (this.administrador.contrasena === '' || this.administrador.contrasena == null) {
          this.$swal(
            'Ups',
            'Agrega una contraseña (8 caracteres mínimo) ',
            'warning'
          )
          return
        }
        if (this.administrador.repeatcontrasena === '' || this.administrador.repeatcontrasena == null) {
          this.$swal( 
            'Ups',
            'Repite la contraseña (8 caracteres mínimo) ',
            'warning'
          )
          return
        }
        if (this.administrador.repeatcontrasena !== this.administrador.contrasena){
          this.$swal(
            'Ups',
            'Las Contraseñas no coinciden',
            'warning'
          )
          return
        }
        const datasend = {
          username: this.administrador.correo,
          email: this.administrador.correo,
          name: this.administrador.nombre,
          lastname: this.administrador.apellido,
          password: this.administrador.contrasena,
          type: 1,
          id_companies: '[]',
          id_projects: '[]'
        }
        administradorServices.crearAdministrador(datasend).then((data)=>{
          this.$swal(
            'Perfecto',
            'Se ha registrado correctamente el administrador',
            'success'
            
          )
          this.$router.push('/administradores')
        })
    },
  }
  }
</script>