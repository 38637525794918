<template>
    <div>
      <form v-on:submit.prevent= "edit_company();">
        <div class="space-y-12 sm:space-y-16">
          <div>
            <h1 class="text-center text-2xl font-semibold leading-7 text-gray-900">Editar empresa </h1>
            <p class="relative w-3/5 my-8 m-auto p-1 text-center text-sm leading-1 text-gray-600">Modifica los datos de la empresa.</p>
            <div class="container w-3/5 m-auto p-3 rounded space-y-10">

              <div class="relative w-full">
                <label for="name" class="absolute font-bold -top-2 left-2 inline-block bg-white px-1 text-xs text-gray-400">Nombre</label>
                <input type="text" id="name_company"  name="name_company" autocomplete="name_company" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" require v-model="company.nombre"/>
                <span class="block bg-white w-full focus:outline-none focus:shadow-outline border border-red-500 rounded-md py-1 px-1 appearance-none leading-normal" v-if="submited && !$v.company.nombre.required">El campo nombre es obligatorio.</span>
              </div>

              <div class="relative w-full">
                <label for="name" class="absolute font-bold -top-2 left-2 inline-block bg-white px-1 text-xs text-gray-400">RFC</label>
                <input type="text" id="rfc_company"  name="rfc_company" autocomplete="rfc_company" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" require v-model="company.rfc"/>
                <span class="block bg-white w-full focus:outline-none focus:shadow-outline border border-red-500 rounded-md py-1 px-1 appearance-none leading-normal" v-if="submited && !$v.company.rfc.required">Inserte el RFC.</span>
              </div>

              <div class="relative w-full">
                <label for="name" class="absolute font-bold -top-2 left-2 inline-block bg-white px-1 text-xs text-gray-400">Dirección</label>
                <textarea id="address_company" name="address_company" rows="3" autocomplete="address_company" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" require v-model="company.direccion" />
                <span class="block bg-white w-full focus:outline-none focus:shadow-outline border border-red-500 rounded-md py-1 px-1 appearance-none leading-normal" v-if="submited && !$v.company.direccion.required">Inserte la dirección de la empresa.</span>
              </div>

              <div class="relative float-label-input">
                <button type="button" class="text-sm font-semibold leading-6 text-gray-900 px-1.5 py-1.5 rounded-md border border-gray-950" style="margin: 1em;"><router-link to="/companies">Cancelar</router-link><router-view /></button>
                <button type="submit" class="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" style="margin: 1em;">Guardar cambios</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </template>
  
  <script>
  import CompaniesServices from '@/services/companies/companies'
  import { required } from '@vuelidate/validators';
  import useVuelidate from '@vuelidate/core';
  export default {
    name: 'EditCompany',
    data() {
      return {
        submited: false,
        company: {
          nombre: '',
          rfc: '',
          direccion: ''
        },
        id_recibido : 0,
      }
    },
    mounted() {
      this.id_recibido = this.$route.params.id;
      CompaniesServices.infoEmpresa({
        options:'info',
        id_company: this.id_recibido, 
      })
      .then(response => {
      this.company.nombre = response.data.name
      this.company.rfc = response.data.rfc
      this.company.direccion = response.data.address
      })
    },
    setup() {
      return { v$: useVuelidate() };
    },
    methods: {
      edit_company () {
        this.v$.$touch();
        if (this.v$.$invalid) {
          console.log('Formulario no válido');
          return;
        }
      CompaniesServices.editarEmpresa({
        options:'update',
        id_company: this.id_recibido,
        name : this.company.nombre,
        rfc : this.company.rfc,          
        address : this.company.direccion
      })
      .then(response => {
        this.$swal({
           title: "¡Buen trabajo!",
           text: response.data.message,
           icon: "success",});
        this.$router.push("/companies");
      })
    }
    },
    validations: {
      company: {
        nombre:{
          required
        },
        rfc: {
          required
        },
        direccion:{
          required
        }
      },
    },
    props: {
      msg: String
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .float-label-input {
    margin: 32px 0;     
  }

  .float-label-input:focus-within label, .float-label-input input:not(:placeholder-shown) + label {
    transform: translateY(-1.5rem) scale(0.75);
    background-color: white;
  }

  .float-label-input textarea:not(:placeholder-shown) + label {
    transform: translateY(-1.5rem) scale(0.75);
    background-color: white;
  }
  </style>
  