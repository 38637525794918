<template>
    <div>
      <div class="px-4 sm:px-6 lg:px-8">
        <div class="sm:flex sm:items-center text-center">
          <h1 class="text-center font-semibold leading-6 text-gray-900">Administradores</h1>
        </div>
        <div class="sm:flex sm:items-center">
          <div class="sm:flex-auto">
            <input class="mt-4 sm:mt-0 sm:flex-none bg-white rounded-3xl p-1.5 mx-auto w-5/12 text-xs"  type="text" placeholder="Buscar por nombre de el Administrador ..." v-model="nombre_buscar" @keyup.enter="listar_administradores(pagina_actual)"/>
            <button type="button" class="  rounded bg-white w-7 px-0.3 ml-1 py-3 text-xs font-semibold text-white shadow-sm hover:bg-gray-200" style="text-align: center!important;" @click="listar_administradores(pagina_actual);" > <img class="mx-auto" src="@/assets/buscar.png" alt="Icono_buscador" width="50%"/> </button>
          </div>
          <div class="mt-4 sm:ml-16 sm:mt-0 sm:items-center">
            <button type="button" class=" sm:flex-auto rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" @click="create_user()" >Crear usuario autorizado</button>
          </div>
        </div>

        <div class="sm:flex">
          <p class="mt-2 text-sm text-gray-500">Listado de administradores:</p>
        </div>
        <div class="mt-8 flow-root">
          <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table class="min-w-full divide-y divide-gray-300">
                  <thead class="bg-gray-50">
                    <tr>
                      <th scope="col" class="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6">Nombre</th>
                      <th scope="col" class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">Apellido</th>
                      <th scope="col" class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">Email</th>
                      <th scope="col" class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">Acciones</th>
                    </tr>
                  </thead>
                  <tbody class="divide-y divide-gray-200 bg-white">
                    <tr v-for="item in list_companies" :key="item.account__id">
                      <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{{ item.account__first_name }}</td>
                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ item.account__last_name }}</td>
                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ item.account__email }}</td>
                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <button type="button" class="rounded bg-gray-900 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 margin: 1em;" style="margin: 1em;" @click="editar_company(item.account__id)">Editar</button>

                        <button type="button" class="rounded bg-purple-400 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" style="margin: 1em;" @click="delete_company(item.account__id)" >Eliminar</button>

                        <button type="button" class="rounded bg-red-500 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" style="margin: 1em;" @click="restringir(item.account__id, item.account__email,item.account__first_name )" >Restingir</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6" v-if="paginas_totales>1">
        <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
          <div class="mx-auto items-center text-center">
            <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
              <span @click="listar_administradores(pagina_actual - 1);">
                <button class="text-xs relative z-10 inline-flex items-center bg-white border border-gray-400 px-4 py-2 font-semibold text-indigo-600 focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ml-1" v-if="pagina_actual -1 > 0"> Anterior </button>
                <span v-else></span>
              </span>
              <span v-for="pagina in pagination" :key="pagina" @click="listar_administradores(pagina);">
                <button class="text-xs relative z-10 inline-flex items-center bg-indigo-500 border border-indigo-500 px-4 py-2 font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" v-if="pagina_actual == pagina">{{ pagina }}</button>
                <button class="text-xs relative z-10 inline-flex items-center bg-white border border-gray-400 px-4 py-2 font-semibold text-indigo-600 focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"  v-else>{{ pagina }}</button>
              </span>

              <span @click="listar_administradores(pagina_actual + 1);">
              <button class=" text-xs relative z-10 inline-flex items-center bg-white border border-gray-400 px-4 py-2 font-semibold text-indigo-600 focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" v-if="pagina_actual < paginas_totales"> Siguiente</button>
              <span v-else></span>
              </span>
            </nav>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import administradorServices from '@/services/Administradores/Administradores'

export default {
    name: "ListAdministradores",

    data() {
      return {
        list_companies: [],
        pagination: [],
        paginas_totales: 0,
        pagina_actual: 1,
        nombre_buscar: '',
        Administrador:{
          nombre: [],
          apellido: [],
          correo: []
        }
      }
      },

    mounted(){
      this.listar_administradores(this.pagina_actual)
    },
    methods: {
      create_user () {
        this.$router.push({
          name: "create_user",
        });
      },
      listar_administradores(pagina) {
        this.pagina_actual = pagina
        const datasend = {
          consulta: this.nombre_buscar,
          page: pagina
        }
        administradorServices.listarprogramadores(datasend)
        .then((data) => {
          this.list_companies = data.data.listado
          this.pagination = data.data.paginacion
          this.paginas_totales = data.data.paginacion.length
          console.log(data)
        })
      },
      editar_company (id) {
        this.$router.push({
          name: 'administradorEdit',
          params:{
            id: id
          }
        })
      },
      restringir (id,email,first_name) {
        console.log('usuarioid', id);
        console.log('usuarioid', email);
        console.log('usuarioid', first_name);
        this.$router.push({
          name: 'restringir',
          params:{
            id: id,
            email: email,
            first_name: first_name
          }
        })
      },
      delete_company (id) {
        this.$swal({
          title: "¿Seguro que desea eliminar el administrador?",
          text: "¡Una vez eliminado, no podrás recuperar la información del registro!",
          icon: "warning",
          showDenyButton: true,
          denyButtonText: `Cancelar`,
          confirmButtonText: 'Eliminar',
          customClass: {
            actions: 'my-actions',
            denyButton: 'order-2',
            confirmButton: 'order-3',
          }
        }).then((result) => {
          if (result.isConfirmed) {
            const datasend = {
              id: id
            }
            administradorServices.deleteAdministrador(datasend)
            .then((response) => {
              this.$swal({
                title: "¡Buen trabajo!",
                text: response.data.message,
                icon: "success"
              });
              this.listar_administradores(1)
            })
          }
        })
      },
    }
}

</script>