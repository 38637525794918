<template>
    <div>
      <div class="px-4 sm:px-6 lg:px-8">
        <div class="sm:flex sm:items-center text-center">
          <h1 class="m-auto text-center font-semibold leading-6 text-gray-900">Adjuntos de la tarea: </h1>
        </div>
        <div class="m-auto"> 
          <br/>
        </div>
        <div class="sm:flex sm:items-center">
          <div class="relative float-label-input justify-start ">
            <select id="project" name="project" autocomplete="project" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-12" v-model="proyecto_id_seleccionado" @change="selected_task(proyecto_id_seleccionado)">
              <option v-for="item in list_projects" :value= "item.id">{{ item.name }} </option>
            </select>
            <label for="project" class="absolute top-3 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white px-2 text-grey-darker items-center">Seleccione un proyecto</label>
          </div>
          <div class=" w-full m-8 mt-4 sm:ml-16 sm:mt-0 sm:items-right">
            <button type="button" class=" justify-end sm:flex-auto rounded-md bg-purple-800 px-3 py-2 text-right text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" @click="crear_task()">Crear Tarea</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import ProjectsServices from '@/services/projects/projects'
  import TasksServices from '@/services/tasks/tasks'


  export default {
    name: 'ListRemarks',
  
    data() {
      return {
        list_projects: [],
        proyecto_id_seleccionado: '',
        }
      },

    mounted() {
        this.listar_proyectos()
        this.task_id = this.$route.params.id_tarea;
        this.proyecto_id_seleccionado = this.$route.params.id_proyecto;
      },
    methods: {
      listar_proyectos () {
        ProjectsServices.proyectosListado({
            options:'list_total', 
          }).then(response => {
          this.list_projects =  response.data.listado
        })
      },
      selected_task (id) {
        this.$router.push({
          name: "TareaProyecto",
          params:{
            id: id,
          }
        });
      },
      crear_task () {
        this.$router.push({
          name: "CrearTarea",
          params:{
            id_project: 0,
          }
        });
      },
    },
    props: {
      msg: String
    }
    }

  const statuses = { Completed: 'text-green-400 bg-green-400/10', Error: 'text-rose-400 bg-rose-400/10' }
  

  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #ffffff;
  }
  </style>
  