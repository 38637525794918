import { axiosInstance } from '@/boot/axios';
import Store from '@/store';

export default {
    crearRepo (data) {
        return axiosInstance.post('/repositorios/crud', data);
    },
    crearlist (data) {
        return axiosInstance.post('/cuentasfilt/crud', data);
    },
    crearlistfiltr (data) {
        return axiosInstance.post('/list_cuentasfilt', data);
    },
    reposListado(data) {
        return axiosInstance.post('/repositorios/crud', data);
    },
    empresasListado(data) {
        return axiosInstance.post('/repositorios/crud', data);
    },
    obtenerurl(data) {
        return axiosInstance.post('/obtenerultimateurl', data);
    },
    actualizarapk(data) {
        return axiosInstance.post('/updateurlapk', data);
    },
    consultarestatus(data) {
        return axiosInstance.post('/estatuscopilacion', data);
    },
    eliminarProyecto(data) {
        return axiosInstance.post('/repositorios/crud', data);
    },
    goUpdateServer(data){
        return axiosInstance.post('/repositorios/updateserver', data);

    },
    checkStatusServer(data){
        return axiosInstance.post('/repositorios/checkstatusserver', data);
    },
    editarProyecto(data) {
        return axiosInstance.post('/repositorios/crud', data);
    },
    infoProyecto(data) {
        return axiosInstance.post('/repositorios/crud', data);
    },
    crearusuario (data) {
        return axiosInstance.post('/create_account_programs', data);
    },
    listprogramador (data) {
        return axiosInstance.post('/check_account_type', data);
    },
}