<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
  import './index.css'
  import home from "./layouts/Home.vue"
  export default {
    name: 'App' ,
    components: {
      home 
    }
  }
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}
</style>
