<link href="{% src 'css/asesoriasExpres-historial.css' %}" rel="stylesheet"></link>
<template>
    <div>
        <div class="space-y-12 sm:space-y-16">
          <div>
            <h1 class="text-center text-2xl font-semibold leading-7 text-gray-900">Solicitar APK</h1>
            <p class="w-1/3 my-8 m-auto p-1 text-left text-sm leading-1 text-gray-600 ajus-p">Da click si deseas solicitar APK </p>
            <div v-if="isUpdating" id="aparecing">
              <h1 class="text-center text-2xl font-semibold leading-7 text-gray-900 ajus-h1">Cargando la actualizacion</h1>
              <div class="ajus-p  ajust-ext">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6 ajus-h1">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                </svg>
              </div>
            </div>
            <div class="container w-3/5 m-auto p-3 rounded ajus-responsive">
              <div class="relative float-label-input">
                <h1 class="text-center text-2xl font-semibold leading-7 text-gray-900 ajus-flexcol">El link del APK es <a href="{{ urlapk }}" class="ajus-link" target="_blank">{{ urlapk }}</a> </h1>
              </div>
            </div>
            <div class="container w-3/5 m-auto p-3 rounded ajus-responsive">
              <div class="relative float-label-input">
                <button @click.stop.prevent="solicitarapk()" class="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" style="margin: 1em;">Solicitar Copilacion</button>
              </div>
            </div>
          </div>
        </div>
    </div>
  </template>
  
<script>
  import repositoriosServices from '@/services/repositorios/repositorios'
  import { required } from '@vuelidate/validators';
  import useVuelidate from '@vuelidate/core';

  export default {    name: 'solicitarAPK',
    data() {
      return {
        urlapk: '',
        infoValor: null,
        intervalId: null,  
        isUpdating: false,
      }
    },
    beforeDestroy() {
      this.clearUpdateInterval();  // Limpia el intervalo cuando el componente se destruye
    },
    beforeRouteLeave(to, from, next) {
      this.clearUpdateInterval();  // Limpia el intervalo antes de cambiar de ruta
      next();
    },
    mounted() {
      this.listar_apk()
    },
    methods: {
      listar_apk() {
        repositoriosServices.obtenerurl()
          .then(response => {
            if (response.data.status) {
              this.urlapk = response.data.ultima_url;
              console.log('url APK', this.urlapk );
            } else {
              console.error(response.data.message);
            }
          })
          .catch(error => {
            console.error('Error al obtener la URL:', error);
          });
      },
      solicitarapk() {
        this.isUpdating = true; 
        console.log(this.isUpdating )
        repositoriosServices.actualizarapk()
        .then(response => {
          const responseData = response.data;
          this.clearUpdateInterval()
          this.isUpdating = true; 
          console.log("Respuesta del servidor:", responseData);
          this.intervalId = setInterval(() => {
            this.checkStatusUpdate();
          }, 1000);
          console.log(this.isUpdating )
        })
        .catch(error => {
          console.error("Error al actualizar el APK:", error);
          this.isUpdating = false;
          console.log(this.isUpdating )
        });
        console.log(this.isUpdating )
      },
      async checkStatusUpdate() {
        try {
          const response = await repositoriosServices.consultarestatus();
          if (response && response.data.valor == '1' ) {
            this.infoValor = response.data.valor;
          } else {
            this.listar_apk()
            this.clearUpdateInterval() 
          }
        } catch (error) {
          console.error('Error al obtener el estatus de compilación:', error);
        }
      },
      clearUpdateInterval() {
        if (this.intervalId) {
          clearInterval(this.intervalId);
          this.intervalId = null;  // Opcional, para limpiar la referencia del intervalo
        }
        this.isUpdating = false;
      },
    },
    validations: {
    },
    props: {
      msg: String
    }
  }
</script>

<style>

.ajus-flexcol{
  display: flex;
  flex-direction: column;
}

.ajus-h1{
  color: red
}
.ajus-link{
  font-size: 20px;
}

.ajust-ext{
  margin-bottom: 5px;
}

.ajus-p{
  display: flex;
  justify-content: center;
}
</style>