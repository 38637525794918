import { axiosInstance } from '@/boot/axios';

export default {
    crearTarea (data) {
        return axiosInstance.post('/task/crud', data);
    },
    tareasListado(data) {
        return axiosInstance.post('/task/crud', data);
    },
    eliminarTarea(data) {
        return axiosInstance.post('/task/crud', data);
    },
    infoTarea(data) {
        return axiosInstance.post('/task/crud', data);
    },
    editarTarea(data) {
        return axiosInstance.post('/task/crud', data);
    },
}