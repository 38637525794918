<template>
    <div>
        <div class="px-4 sm:px-6 lg:px-8">
        <div class="sm:flex sm:items-center text-center">
            <h1 class="text-center font-semibold leading-6 text-gray-900">Repositorios</h1>
        </div>
        <div class="sm:flex sm:items-center">
            <div class="sm:flex-auto">
            <input class="mt-4 sm:mt-0 sm:flex-none bg-white rounded-3xl p-1.5 mx-auto w-5/12 text-xs new-clasajus"  type="text" placeholder="Buscar proyecto por su nombre ..." v-model="nombre_buscar" @keyup.enter="repos_list(pagina_actual);"/>
            <button type="button" class=" rounded bg-white w-7 px-0.3 ml-1 py-3 text-xs font-semibold text-white shadow-sm hover:bg-gray-200" style="text-align: center!important;" @click="repos_list(pagina_actual);" > <img class="mx-auto" src="@/assets/buscar.png" alt="Icono_buscador" width="50%"/> </button>
            </div>
            <div class="mt-4 sm:ml-16 sm:mt-0 sm:items-center">
            <button type="button" class="rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"><router-link to="/updateprollect/create">Crear Proyecto</router-link><router-view /></button>
            </div>
        </div>

        <div class="sm:flex">
            <p class="mt-2 text-sm text-gray-500">Listado de Repositorios trabajados:</p>
        </div>
        <div class="mt-8 flow-root">
            <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg" style="padding-bottom: 30vh;">
                <table class="min-w-full divide-y divide-gray-300 pb-28">
                    <thead class="bg-gray-50">
                    <tr>
                        <th scope="col" class="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6">Proyecto</th>
                        <th scope="col" class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">Repositorio</th>
                        <th scope="col" class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">Tipo</th>
                        <th scope="col" class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">Estado</th>
                        <th scope="col" class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">Acciones</th>
                        <th scope="col" class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">logs</th>

                    </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-200 bg-white">
                    <tr v-for="item in list_projects" :key="item.id">
                        <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{{ item.proyecto }}</td>
                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ item.repositorio }}</td>
                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ item.tipo }}</td>
                        <td v-if="item.estado == ''" class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">Sin estatus reciente</td>
                        <td v-if="item.estado != ''" class="px-3 py-4 text-sm text-gray-500">{{ item.estado }}</td>
                        <!-- <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 aparecig1 ">
                            <div class="dropdown">
                                <button class="dropbtn" >Acciones</button>
                                <div class="dropdown-content">
                                    <a class="cursor-pointer" @click="updateServer(item.id)">Actualizar</a>
                                    <a href="#">Ver logs</a>
                                    <a href="#">Limpiar logs</a>
                                    <a href="#">Descargar logs </a>
                                    <a href="#">Reparar</a>
                                    <a class="cursor-pointer"@click="updateServer(item.id)">Editar</a>
                                    <a class="cursor-pointer" @click="delete_project(item.id)" >Eliminar</a>
                                    <a class="cursor-pointer" @click="create_user()">crear usuario Autorizado</a>
                                </div>
                            </div>
                        </td> -->
                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <Menu as="div" class="relative inline-block text-left">
                            <div>
                              <MenuButton class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                                Acciones
                                <ChevronDownIcon class="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                              </MenuButton>
                            </div>

                            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                              <MenuItems class="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <div class="py-1">
                                  <MenuItem v-slot="{ active }">
                                    <a href="#" @click="updateServer(item.id)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Actualizar</a>
                                  </MenuItem>
                                  <MenuItem v-slot="{ active }">
                                    <a href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Ver logs</a>
                                  </MenuItem>
                                </div>
                                <div class="py-1">
                                  <MenuItem v-slot="{ active }">
                                    <a href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Limpiar logs</a>
                                  </MenuItem>
                                  <MenuItem v-slot="{ active }">
                                    <a href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Descargar logs</a>
                                  </MenuItem>
                                </div>
                                <div class="py-1">
                                  <MenuItem v-slot="{ active }">
                                    <a href="#" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Reparar</a>
                                  </MenuItem>
                                  <MenuItem v-slot="{ active }">
                                    <a href="#" @click="editar_project(item.id)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Editar</a>
                                  </MenuItem>
                                </div>
                                <div class="py-1">
                                  <MenuItem v-slot="{ active }">
                                    <a href="#" @click="delete_project(item.id)" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">Eliminar</a>
                                  </MenuItem>
                                  <MenuItem v-slot="{ active }">
                                    <a href="#" @click="create_user()" :class="[active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm']">crear usuario Autorizado</a>
                                  </MenuItem>
                                </div>
                              </MenuItems>
                            </transition>
                          </Menu>
                        </td>
                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        </td>
                    </tr>
                    </tbody>
                </table>
                </div>
            </div>
            </div>
        </div>
        </div>
        <!-- Paginador. Por default renderiza la página 1. El evento clic de cada botón actualiza el valor de la variable pagina_actual, la cual se pasa como argumento a la función listar proyectos -->
        <div class="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6" v-if="paginas_totales>1">
        <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <div>
            <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                <span @click="repos_list(pagina_actual - 1);">
                <button class="text-xs relative z-10 inline-flex items-center bg-white border border-gray-400 px-4 py-2  font-semibold text-indigo-600 focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ml-1" v-if="pagina_actual - 1 > 0"> Anterior</button>
                <span v-else></span>
                </span>
                <span v-for="pagina in pagination" :key="pagina" @click="repos_list(pagina + 1);">
                <button class="text-xs relative z-10 inline-flex items-center bg-indigo-500 border border-indigo-500 px-4 py-2 font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" v-if="pagina_actual == pagina + 1">{{ pagina + 1 }}</button>
                <button class="text-xs relative z-10 inline-flex items-center bg-white border border-gray-400 px-4 py-2 font-semibold text-indigo-600 focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"  v-else>{{ pagina + 1 }}</button>
                </span>

                <span @click="repos_list(pagina_actual + 1);">
                <button class=" text-xs relative z-10 inline-flex items-center bg-white border border-gray-400 px-4 py-2 font-semibold text-indigo-600 focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" v-if="pagina_actual < paginas_totales"> Siguiente</button>
                <span v-else></span>
                </span>
            </nav>
            </div>
        </div>
        </div>
    </div>
</template>

<script setup>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
</script>

<script>
import ProjectsServices from '@/services/projects/projects'
import CompaniesServices from '@/services/companies/companies'
import repositoriosServices from '@/services/repositorios/repositorios'
import AccountsServices from '@/services/accounts/accounts'
  export default {
    name: 'ListProjects',
    
    data() {
      return {
        menuVisible: false,
        list_companies: [],
        list_projects: [],
        pagination: [],
        paginas_totales: 0,
        pagina_actual: 1,
        nombre_buscar: '',
        showLogsColumn: false,
        projectIdToDelete: null,
        updatespending: [],
        checkerstatus: null,
        id_usuario: '',
        list_programs: [],
        }
      },
    beforeDestroy(){
        clearInterval(this.checkerstatus)
    },
    mounted() {
      this.listar_id()
        this.listar_empresas()
        this.repos_list(this.pagina_actual)
        this.checkerstatus = setInterval(() =>{this.checkStatusUpdate()}, 1000)
      },
    methods: {
      toggleMenu() {
          this.menuVisible = !this.menuVisible;
      },
      listar_id() {
        repositoriosServices.listprogramador({
            options:'list_total', 
          }).then(response => {
          this.list_programs =  response.data.listado
          console.log(this.list_programs)
        })
      },
      repos_list (pagina) {
        this.pagina_actual = pagina
        repositoriosServices.reposListado({
          options:'list',
          page: pagina,
          consulta: this.nombre_buscar,  
        }).then(response => {
          this.list_projects =  response.data.listado
          this.paginas_totales = response.data.paginacion.length
          this.pagination = [...Array(this.paginas_totales).keys()];
          console.log(this.list_projects);
          console.log('paginas totales ',this.paginas_totales);
        })
      },
      editar_project (id) {
        this.$router.push({
          name: "Editarrepo",
          params:{
            id: id,
          }
        });
        
        console.log("Editar proyecto con ID:", id);
      },

      create_user () {
        this.$router.push({
          name: "create_user",
        });
      },
      obt_ckick(id) {
        // Almacenar el ID del proyecto en la variable projectIdToDelete
        this.projectIdToDelete = id;
        console.log("ID del proyecto almacenado:", this.projectIdToDelete);
      },
      checkStatusUpdate(){
        if(this.updatespending.length > 0){
            repositoriosServices.checkStatusServer(
                        {
                            listpend: JSON.stringify(this.updatespending)
                        }
            ).then(response=>{
                for (var i = response.data.servers.length - 1; i >= 0; i--) {
                    var estado = response.data.servers[i].estado
                    var pos = this.list_projects.findIndex(x => x.id === response.data.servers[i].id)
                    this.list_projects[pos].estado = estado
                    
                    if(estado.includes('Servidor actualizado. Última actualización:') || estado.includes('Error en actualización. Última actualización:')){

                            const index = this.updatespending.indexOf(response.data.servers[i].id);
                            if (index > -1) { // only splice array when item is found
                              this.updatespending.splice(index, 1); // 2nd parameter means remove one item only
                            }
                    }
                }
            })
        }
      },
      updateServer(item){
        repositoriosServices.goUpdateServer(
            {
                id: item
            }
        ).then(response=>{
            this.$swal({
               title: "Actualización solicitada",
               text: "Actualización programada con éxito",
               showConfirmButton: false,
               timer: 1500,
               icon: "success",
           });
            this.updatespending.push(item)
        })
      },
      listar_empresas () {
        repositoriosServices.empresasListado({
            options:'list_total', 
          }).then(response => {
          this.list_companies =  response.data.listado
        })
      },
      delete_project (id) {
        console.log(id)
        this.$swal({
          title: "¿Seguro que desea eliminar el repositorio ?",
          text: "¡Una vez eliminado, no podrás recuperar la información del registro!",
          icon: "warning",
          showDenyButton: true,
          denyButtonText: `Cancelar`,
          confirmButtonText: 'Eliminar',
          customClass: {
            actions: 'my-actions',
            denyButton: 'order-2',
            confirmButton: 'order-3',
          }
        }).then((result) => {
          if (result.isConfirmed) {
            console.log(id)
            repositoriosServices.eliminarProyecto({
            options:'delete',
            id: id})
            .then(response => {
              this.$swal({
                title: "¡Buen trabajo!",
                text: response.data.message,
                icon: "success",});
                this.list_projects = []
                this.repos_list(1)
            })
            
          }
        })
      },
       handleAction(action) {
            if (action === "Ver logs") {
                this.showLogsColumn = true;
            } else {
                this.showLogsColumn = false;
            }
       },
      project_tasks (id_project) {
        this.$router.push({
          name: "TareaProyecto",
          params:{
            id: id_project,
          }
        });
      }, 
    },
    props: {
      msg: String,
    }
    }

  const statuses = { Completed: 'text-green-400 bg-green-400/10', Error: 'text-rose-400 bg-rose-400/10' }
  

</script>
  
    <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
 @media ( max-width: 700px){
    .new-clasajus{
        width: 75vw;

    }
}
.action-btn {
    display: block;
    margin: 10px 0;
}

.menu {
    border: 1px solid #ccc;
    padding: 10px;
    background-color: white;
    position: absolute;
}

.menu-item {
    display: block;
    margin: 5px 0;
    cursor: pointer;
}
/* Style The Dropdown Button */

.dropbtn {
    background-color: #a4a4a4;
    color: white;
    padding: 5px;
    font-size: 16px;
    border-radius: 10%;
    border: none;
    cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #f1f1f1}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
  position: fixed;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #888888;;
}
.h3 {
    margin: 40px 0 0;
}
.ul {
    list-style-type: none;
    padding: 0;
}
.li {
    display: inline-block;
    margin: 0 10px;
}
.a {
    color: #ffffff;
}
</style>