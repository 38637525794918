<template>
    <div>
      <div class="px-4 sm:px-6 lg:px-8">
        <div class="sm:flex sm:items-left text-left">
          <h1 class="-mx-4 -my-2 text-left font-semibold leading-6 text-gray-900">Tareas</h1>
        </div>
        <div class="mt-8 flow-root">
          <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8" v-if="!list_tasks.length">
            <div class=" w-full m-8 mt-4 sm:ml-16 sm:mt-0 sm:items-right">
              El proyecto {{ proyecto.name }}, no tiene tareas asignadas
            </div>
            <div class=" w-full m-8 mt-4 sm:ml-16 sm:mt-0 alignt-right ">
              <button type="button" class=" justify-end sm:flex-auto rounded-md bg-purple-800 px-3 py-2 text-right text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" @click="crear_task_project(proyecto_id_seleccionado)">Agregar una tarea al Proyecto</button>
            </div>
          </div>
          <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8" v-else>
            <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <p class="my-8 text-sm text-start text-gray-500">Listado de tareas del proyecto:  {{ proyecto.name }}</p>
              <div class="sm:flex sm:items-center">
                <div class=" w-full m-8 mt-4 sm:ml-16 sm:mt-0 sm:items-right">
                  <input class="mt-4 sm:mt-0 sm:flex-none bg-white rounded-3xl p-1.5 mx-auto w-5/12 text-xs"  type="text" placeholder="Buscar tarea por nombre ..." v-model="nombre_buscar" @keyup.enter="listar_tareas_empresa(pagina_actual);"/>
                  <button type="button" class="  rounded bg-white w-7 px-0.3 ml-1 py-3 text-xs font-semibold text-white shadow-sm hover:bg-gray-200" style="text-align: center!important;" @click="listar_tareas_empresa(pagina_actual);" > <img class="mx-auto" src="@/assets/buscar.png" alt="Icono_buscador" width="50%"/> </button>
                  <button class="w-64"></button>
                  <button type="button" class=" justify-end sm:flex-auto rounded-md bg-purple-800 px-3 py-2 text-right text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" @click="crear_task_project(proyecto_id_seleccionado)">Agregar una tarea al Proyecto</button>
                </div>
              </div>
              <div class="overflow-visible shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table class="min-w-full divide-y divide-gray-300">
                  <thead class="bg-gray-50">
                    <tr>
                      <th scope="col" class="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6">Título</th>
                      <th scope="col" class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">Descripción</th>
                      <th scope="col" class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">Tipo </th>
                      <th scope="col" class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">Tiempo de desarollo</th>
                      <th scope="col" class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">Estatus</th>
                      <th scope="col" class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">Acciones</th>

                    </tr>
                  </thead>
                  <tbody class="divide-y divide-gray-200 bg-white">
                    <tr v-for="item in list_tasks" :key="item.id">
                      <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{{ item.title }}</td>
                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ item.description }}</td>
                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"><span v-if="item.tipo == 1">Error</span><span v-else>Adición</span></td>
                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ item.development_time_str }}</td>
                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <div class= "items-end ">
                          <select id="type" name="type" autocomplete="type-task" class="w-30 rounded-md border-0 border-white py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-12" @change="change_status(item.id);">
                            <option class="font-bold" selected>{{ status_values[item.status] }}</option>
                            <option v-for="id in [...Array(5).keys()]" v-if="id!=item.status-1" :value= "(id+1)"> {{ status_values[id+1] }}</option>
                          </select>
                        </div>
                      </td>
                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <div class="dropdown"@mouseover="upHere = true" @mouseleave="upHere = false">
                          <button class="dropbtn">Seleccione una opción <i class="fa fa-chevron-down" aria-hidden="true" v-show="upHere"></i></button>
                          <div class="dropdown-content">
                            <a href="#"><button type="button" class="block rounded bg-transparent text-xm font-semibold text-purple-800 shadow-sm hover:bg-transparent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 my-px" @click="editar_task(item.id)">Editar</button></a>
                            <a href="#"><button type="button" class="block rounded bg-transparent text-xm font-semibold text-purple-800 shadow-sm hover:bg-transparent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 my-px " @click="delete_task(item.id)" >Eliminar</button></a>
                            <!-- <a href="#"><button type="button" class="block rounded bg-transparent text-xm font-semibold text-purple-800 shadow-sm hover:bg-transparent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 my-px" @click="crear_adjunt_task(item.id)">Agregar adjunto</button></a> -->
                            <a href="#"><button type="button" class="block rounded bg-transparent text-xm font-semibold text-purple-800 shadow-sm hover:bg-transparent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 my-px" @click="listar_adjunts(item.id)">Ver adjuntos</button></a>
                            <!-- <a href="#"><button type="button" class="block rounded bg-transparent text-xm font-semibold text-purple-800 shadow-sm hover:bg-transparent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 my-px " @click="crear_remark_task(item.id)" >Agregar notas</button></a> -->
                            <a href="#"><button type="button" class="block rounded bg-transparent text-xm font-semibold text-purple-800 shadow-sm hover:bg-transparent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 my-px" @click="listar_remarks(item.id)" >Ver notas</button></a>
                            <a href="#"><button type="button" class="block rounded bg-transparent text-xm font-semibold text-purple-800 shadow-sm hover:bg-transparent focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 my-px" @click="listar_status(item.id)" >Ver historial de status</button>
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6" v-if="paginas_totales>1">
        <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
          <div class="mx-auto items-center text-center">
            <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
              <span @click="listar_tareas_empresa(pagina_actual - 1);">
                <button class="text-xs relative z-10 inline-flex items-center bg-white border border-gray-400 px-4 py-2 font-semibold text-indigo-600 focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ml-1" v-if="pagina_actual - 1 > 0"> Anterior</button>
                <span v-else></span>
              </span>
              <span v-for="pagina in pagination" :key="pagina" @click="listar_tareas_empresa(pagina + 1);">
                <button class="text-xs relative z-10 inline-flex items-center bg-indigo-500 border border-indigo-500 px-4 py-2 font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" v-if="pagina_actual == pagina + 1">{{ pagina + 1 }}</button>
                <button class="text-xs relative z-10 inline-flex items-center bg-white border border-gray-400 px-4 py-2 font-semibold text-indigo-600 focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"  v-else>{{ pagina + 1 }}</button>
              </span>

              <span @click="listar_tareas_empresa(pagina_actual + 1);">
              <button class=" text-xs relative z-10 inline-flex items-center bg-white border border-gray-400 px-4 py-2 font-semibold text-indigo-600 focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" v-if="pagina_actual < paginas_totales"> Siguiente</button>
              <span v-else></span>
              </span>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import ProjectsServices from '@/services/projects/projects'
  import TasksServices from '@/services/tasks/tasks'
  import HistorialServices from '@/services/history_status/history_status'


  export default {
    name: 'SelectedTask',
  
    data() {
      return {
        status_values: {
          1: 'Pendiente',
          2: 'En revisión',
          3: 'En desarrollo',
          4: 'En pruebas',
          5: 'Terminado'
        },
        list_projects: [],
        proyecto_id_seleccionado: '',
        proyecto: '',
        list_tasks: [],
        pagination: [],
        paginas_totales: 0,
        pagina_actual: 1,
        nombre_buscar: '',
        upHere: false,
        }
      },

    mounted() {
        this.proyecto_id_seleccionado = this.$route.params.id;
        this.listar_proyectos()
        this.listar_tareas_empresa(this.pagina_actual)
      },
    methods: {
      
      listar_proyectos () {
        ProjectsServices.proyectosListado({
            options:'list_total', 
          }).then(response => {
          this.list_projects =  response.data.listado
          this.proyecto = this.list_projects.find(item => item.id == this.proyecto_id_seleccionado);
        })
      },
      listar_tareas_empresa (pagina) {
        this.pagina_actual = pagina
        TasksServices.tareasListado({
          options:'list',
          id_project: this.proyecto_id_seleccionado,
          page: pagina,
          consulta: this.nombre_buscar,  
        }).then(response => {
          this.list_tasks =  response.data.listado
          console.log(this.list_tasks, 'listado de tareas')
          this.paginas_totales = response.data.paginacion.length
          this.pagination = [...Array(this.paginas_totales).keys()];
        })
      },
      crear_task_project () {
        this.$router.push({
          name: "CrearTarea",
          params:{
            id_project: this.proyecto_id_seleccionado,
          }
        });
      },
      editar_task (id_task) {
      this.$router.push({
          name: "EditarTarea",
          params:{
            id: id_task,
            id_project: this.proyecto_id_seleccionado,
          }
        });
      },
      delete_task (id_task) {
        this.$swal({
          title: "¿Seguro que desea eliminar la tarea seleccionada?",
          text: "¡Una vez eliminada, no podrás recuperar la información del registro!",
          icon: "warning",
          showDenyButton: true,
          denyButtonText: `Cancelar`,
          confirmButtonText: 'Eliminar',
          customClass: {
            actions: 'my-actions',
            denyButton: 'order-2',
            confirmButton: 'order-3',
          }
        }).then((result) => {
          if (result.isConfirmed) {
            TasksServices.eliminarTarea({
            options:'delete',
            id: id_task})
            .then(response => {
              this.$swal({
                title: "¡Buen trabajo!",
                text: response.data.message,
                icon: "success",});
                this.list_tasks = []
                this.listar_tareas_empresa(1)
            })
          }
        })
      },
      change_status(id_tarea){
        HistorialServices.cambiarEstatus({
          options:'new',
          id_task: id_tarea,
          status: event.target.value,
          user_id: localStorage.getItem('user_id')
        })
        .then(response => {
              this.$swal({
                title: "¡Buen trabajo!",
                text: response.data.message,
                icon: "success",});
                this.list_tasks = []
                this.listar_tareas_empresa(1)
            })
      },
      crear_adjunt_task (id_task) {
        this.$router.push({
          name: "CrearAdjunto",
          params:{
            id_tarea: id_task,
            id_proyecto: this.proyecto_id_seleccionado,
          }
        });
      },
      listar_adjunts (id_task) {
        this.$router.push({
          name: "AdjuntosTarea",
          params:{
            id_tarea: id_task,
            id_proyecto: this.proyecto_id_seleccionado,
          }
        });
      },
      crear_remark_task (id_task) {
        this.$router.push({
          name: "CrearObservacion",
          params:{
            id_tarea: id_task,
            id_proyecto: this.proyecto_id_seleccionado,
          }
        });
      },
      listar_remarks (id_task) {
        this.$router.push({
          name: "ObservacionesTarea",
          params:{
            id_tarea: id_task,
            id_proyecto: this.proyecto_id_seleccionado,
          }
        });
      },
      listar_status(id_task){
        this.$router.push({
          name: "history_status_tasks",
          params:{
            id_tarea: id_task,
            id_proyecto: this.proyecto_id_seleccionado,
          }
        });
      }
    },
    props: {
      msg: String
    }
    }

  const statuses = { Completed: 'text-green-400 bg-green-400/10', Error: 'text-rose-400 bg-rose-400/10' }
  

  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .dropbtn {
  background-color: white;
  color: rgb(107 33 168);
  font-weight: 500;
  border-color: rgb(107 33 168);
  border-width: 1px;
  padding-top: 0.25rem; /* 4px */
  padding-bottom: 0.25rem;
  font-size: 16px;
  border-radius: 0.25rem;
  padding-left: 0.375rem; /* 6px */
  padding-right: 0.375rem; /* 6px */
  }
  .dropdown {
    position: relative;
    display: inline-block;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }

  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }

  .dropdown-content a:hover {background-color: #ddd;}

  .dropdown:hover .dropdown-content {
    position: absolute;
    display: block;
    z-index: 10;
  }

  .dropdown:hover .dropbtn {
    background-color: rgb(107 33 168);
    color: white;}
  
  </style>
  