import { axiosInstance } from '@/boot/axios';

export default {
    crearRepo (data) {
        return axiosInstance.post('/templates/crud', data);
    },
    reposListado(data) {
        return axiosInstance.post('/templates/crud', data);
    },
    empresasListado(data) {
        return axiosInstance.post('/templates/crud', data);
    },
    eliminarProyecto(data) {
        return axiosInstance.post('/templates/crud', data);
    },
    checkStatusServer(data){
        return axiosInstance.post('/templates/checkstatusserver', data);
    },
    editarProyecto(data) {
        return axiosInstance.post('/templates/crud', data);
    },
    infoProyecto(data) {
        return axiosInstance.post('/templates/crud', data);
    },
    createTemplate(data) {
        return axiosInstance.post('/templates/crud', data);
    },
    infoTemplate(data) {
        return axiosInstance.post('/templates/crud', data);
    },
    uploadImage(data) {
        return axiosInstance.post('/add_dropzone', data);
    },
    deleteImage(data) {
        return axiosInstance.post('/delete_dropzone', data);
    }
}