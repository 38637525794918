<template>
    <div>
      <form v-on:submit.prevent= "create_task();">
        <div class="space-y-12 sm:space-y-16">
          <div>
            <h1 class="text-center text-2xl font-semibold leading-7 text-gray-900">Crear nueva tarea </h1>
            <p class="w-3/5 mt-8 m-auto p-1 text-left text-sm leading-1 text-gray-600">Registra la información de la tarea a generar.</p>
            <div class="container w-3/5 m-auto p-3 rounded">

              <div class="relative float-label-input">
                  <input type="text" id="title" name="title" autocomplete="title" placeholder="" class="block w-full bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-md py-3 px-3 appearance-none leading-normal focus:border-blue-400" v-model="task.titulo" />
                  <label for="title" class="absolute top-3 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white px-2 text-grey-darker">Título</label>
                  <span class="block bg-white w-full focus:outline-none focus:shadow-outline border border-red-500 rounded-md py-1 px-1 appearance-none leading-normal" v-if="submited && !$v.task.titulo.required">Agregue un título a la tarea.</span>
              </div>

              <div class="relative float-label-input">
                <textarea id="description" name="description" rows="3" autocomplete="description" class="block w-full bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-md py-3 px-3 appearance-none leading-normal focus:border-blue-400 placeholder:text-center  placeholder:text-gray-400" placeholder="" v-model= "task.descripcion"></textarea>
                <label for="description" class="absolute top-3 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white px-2 text-grey-darker">Descripción</label>
                <span class="block bg-white w-full focus:outline-none focus:shadow-outline border border-red-500 rounded-md py-1 px-1 appearance-none leading-normal" v-if="submited && !$v.task.descripcion.required">Inserte la descripción de la tarea a realizar.</span>
              </div>

              <div class="relative float-label-input">
                <select id="type" name="type" autocomplete="type-task" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="task.tipo">
                  <option value= "1">Error</option>
                  <option value= "2">Adición </option>
                </select>
                <label for="type" class="absolute top-3 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white px-2 text-grey-darker">Seleccione el tipo de tarea</label>
                <span class="block bg-white w-full focus:outline-none focus:shadow-outline border border-red-500 rounded-md py-1 px-1 appearance-none leading-normal" v-if="submited && !$v.task.tipo.required">Seleccione el tipo de tarea. </span>
              </div>

              <div class="relative float-label-input" v-if="!id_proyecto">
                <select id="proyecto" name="proyecto" autocomplete="proyecto" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="task.proyecto">
                  <option v-for="item in list_projects" :value= "item.id">{{ item.name }}</option>
                </select>
                <label for="project" class="absolute top-3 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white px-2 text-grey-darker">Seleccione un proyecto</label>
                <span class="block bg-white w-full focus:outline-none focus:shadow-outline border border-red-500 rounded-md py-1 px-1 appearance-none leading-normal" v-if="submited && !$v.task.proyecto.required">Seleccione un proyecto.</span> 
              </div>
              <div class="relative float-label-input" v-else>
                <p type="text" id="title" name="title" autocomplete="title" placeholder="" class="block w-full bg-white text-gray-400 text-left focus:outline-none focus:shadow-outline border border-gray-300 rounded-md py-3 px-3 appearance-none leading-normal focus:border-blue-400"> Proyecto seleccionado: {{ proyecto_item.name }}</p>
              </div>

              <!-- <div class="relative float-label-input">
                <select id="type" name="type" autocomplete="type-task" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-12" v-model="task.estatus">
                  <option value= "1">Pendiente</option>
                  <option value= "2">En revisión </option>
                  <option value= "3">En desarrollo</option>
                  <option value= "4">En pruebas </option>
                  <option value= "5">Terminado</option>
                </select>
                <label for="type" class="absolute top-3 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white px-2 text-grey-darker">Seleccione el estatus de tarea</label>
                <span class="block bg-white w-full focus:outline-none focus:shadow-outline border border-red-500 rounded-md py-1 px-1 appearance-none leading-normal" v-if="submited && !$v.task.estatus.required">Seleccione el estatus de tarea. </span>
              </div> -->

              <div class="relative float-label-input">
                <button type="button" class="text-sm font-semibold leading-6 text-gray-900 px-1.5 py-1.5 rounded-md border border-gray-950" style="margin: 1em;" v-if="!id_proyecto"  @click="cancel();">Cancelar</button>
                <button type="button" class="text-sm font-semibold leading-6 text-gray-900 px-1.5 py-1.5 rounded-md border border-gray-950" style="margin: 1em;" v-else @click="selected_task();">Cancelar</button>
                <button type="submit" class="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" style="margin: 1em;">Generar Tarea</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </template>
  
  <script>
  import ProjectsServices from '@/services/projects/projects'
  import TasksServices from '@/services/tasks/tasks'
  import { required } from '@vuelidate/validators';
  import useVuelidate from '@vuelidate/core';
  
  export default {
    name: 'CreateTask',
    data() {
      return {
        submited: false,
        task: {
          titulo: '',
          descripcion: '',
          tipo: '',
          proyecto: '',
          //estatus: ''
        },
        list_projects: [],
        proyecto_item: '',
        id_proyecto:'',
      }
    },
    mounted() {
      this.listar_proyectos()
      this.id_proyecto = this.$route.params.id_project;
      this.task.proyecto = this.id_proyecto;
    },
    setup() {
      return { v$: useVuelidate() };
    },
    methods: {
      listar_proyectos () {
        ProjectsServices.proyectosListado({
            options:'list_total', 
          }).then(response => {
          this.list_projects =  response.data.listado
          this.proyecto_item = this.list_projects.find(item => item.id == this.id_proyecto);
          console.log(this.list_projects);
        })
      },
      selected_task () {
        this.$router.push({
          name: "TareaProyecto",
          params:{
            id: this.id_proyecto,
          }
        });
      },
      cancel () {
        this.$router.push({
          name: "tasks",
        });
      },
      create_task () {
        this.v$.$touch();
        if (this.v$.$invalid) {
          console.log('Formulario no válido');
          return;
        }
        this.id_proyecto = this.task.proyecto
        TasksServices.crearTarea({
          options:'new',
          title: this.task.titulo,
          description: this.task.descripcion,
          type: this.task.tipo,
          id_p: this.task.proyecto,
          user_id: localStorage.getItem('user_id')
          // status: this.task.estatus
        })
        .then(response => {
          this.$swal({
            title: "¡Buen trabajo!",
            text: response.data.message,
            icon: "success",});
          if(!this.id_proyecto) {
            this.$router.push("/tasks");
          }
          else {
            this.selected_task()
          }
        })
      }
    },
    validations: {
      task: {
        titulo:{
          required
        },
        descripcion: {
          required
        },
        tipo:{
          required
        },
        proyecto:{
          required
        },
        // estatus:{
        //   required,
        // }
      },
    },
    props: {
      msg: String
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>

  .float-label-input {
    margin: 32px 0;     
  }

  .float-label-input:focus-within label, .float-label-input input:not(:placeholder-shown) + label {
    transform: translateY(-1.5rem) scale(0.75);
    background-color: white;
  }

  .float-label-input textarea:not(:placeholder-shown) + label {
    transform: translateY(-1.5rem) scale(0.75);
    background-color: white;
  }

 .float-label-input select:not(:placeholder-shown) + label {
    transform: translateY(-1.5rem) scale(0.75);
    background-color: white;
  }
  </style>
  