import { axiosInstance } from '@/boot/axios';

export default {
    crearProyecto (data) {
        return axiosInstance.post('/projects/crud', data);
    },
    proyectosListado(data) {
        return axiosInstance.post('/projects/crud', data);
    },
    eliminarProyecto(data) {
        return axiosInstance.post('/projects/crud', data);
    },
    infoProyecto(data) {
        return axiosInstance.post('/projects/crud', data);
    },
    editarProyecto(data) {
        return axiosInstance.post('/projects/crud', data);
    },
}