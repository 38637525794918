<template>
    <div>
      <div class="px-4 sm:px-6 lg:px-8">
        <div class="sm:flex sm:items-center text-center">
          <h1 class="m-auto text-center font-semibold leading-6 text-gray-900">Listado de archivos adjuntos</h1>
        </div>
        <div class="mt-8 flow-root">
          <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8" v-if="!list_adjunts.length">
            <div class=" w-full my-8 sm:mt-0 sm:items-center text-left">
              La tarea no tiene archivos adjuntos.
            </div>
            <div class=" w-full my-8 sm:mt-0 sm:items-center">
              <button type="button" class=" justify-end sm:flex-auto rounded-md bg-purple-800 px-3 py-2 text-right text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"  @click="crear_adjunt_task(id_task)">Agregar un adjunto</button><!--v-model = "nombre_buscar"-->
            </div>
          </div>
          <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8" v-else>
            <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <p class="mt-2 text-sm text-start text-gray-500">Listado de adjuntos de la tarea:  {{ tarea.title }}</p>
              <div class="sm:flex sm:items-center">
                <div class=" w-full m-8 mt-4 sm:ml-16 sm:mt-0 sm:items-right">
                  <button type="button" class=" justify-end sm:flex-auto rounded-md bg-purple-800 px-3 py-2 text-right text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" @click="crear_adjunt_task(id_task)">Agregar un adjunto a la tarea</button>
                </div>
              </div>
              <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table class="min-w-full divide-y divide-gray-300">
                  <thead class="bg-gray-50">
                    <tr>
                      <th scope="col" class="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6">URL</th>
                      <th scope="col" class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">Hora y fecha de creación</th>
                      <th scope="col" class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">Autor</th>
                      <th scope="col" class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">Acciones</th>
                    </tr>
                  </thead>
                  <tbody class="divide-y divide-gray-200 bg-white">
                    <tr v-for="item in list_adjunts" :key="item.id">
                      <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"> <a :href="server+item.url" target="_blank">{{ getName(item.url) }}</a></td>
                      <!-- Falta crear la función que descarga el archivo, podría ser implementado un <a :href="#" download="nombre-del-archivo" @click="download(item.id)">{{ item.url }}</a>-->
                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ item.datatime_adj_str }}</td>
                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ item.author_adj__username }}</td>
                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <button type="button" class="rounded bg-gray-900 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 margin: 1em;" style="margin: 1em;" @click="editar_adjunt(item.id)">Editar</button>

                        <button type="button" class="rounded bg-purple-400 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" style="margin: 1em;" @click="delete_adjunt(item.id)" >Eliminar</button>

                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6" v-if="paginas_totales>1">
        <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
          <div class="mx-auto items-center text-center">
            <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
              <span @click="listar_adjuntos_tarea(pagina_actual - 1);">
                <button class="text-xs relative z-10 inline-flex items-center bg-white border border-gray-400 px-4 py-2 font-semibold text-indigo-600 focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ml-1" v-if="pagina_actual - 1 > 0"> Anterior</button>
                <span v-else></span>
              </span>
              <span v-for="pagina in pagination" :key="pagina" @click="listar_adjuntos_tarea(pagina + 1);">
                <button class="text-xs relative z-10 inline-flex items-center bg-indigo-500 border border-indigo-500 px-4 py-2 font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" v-if="pagina_actual == pagina + 1">{{ pagina + 1 }}</button>
                <button class="text-xs relative z-10 inline-flex items-center bg-white border border-gray-400 px-4 py-2 font-semibold text-indigo-600 focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"  v-else>{{ pagina + 1 }}</button>
              </span>

              <span @click="listar_adjuntos_tarea(pagina_actual + 1);">
              <button class=" text-xs relative z-10 inline-flex items-center bg-white border border-gray-400 px-4 py-2 font-semibold text-indigo-600 focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" v-if="pagina_actual < paginas_totales"> Siguiente</button>
              <span v-else></span>
              </span>
            </nav>
          </div>
        </div>
      </div>
      <div class=" w-full m-auto">
        <button type="button" class="my-8 justify-end sm:flex-auto rounded-md  bg-white px-3 py-1 text-right text-sm font-semibold text-purple-400 shadow-sm hover:bg-purple-800 hover:text-white" @click="project_tasks(proyecto_id_seleccionado)">Ver tareas del proyecto</button>
      </div>
    </div>
  </template>
  
  <script>
  import AdjuntsServices from '@/services/adjunts/adjunts'
  import TasksServices from '@/services/tasks/tasks'


  export default {
    name: 'SelectedAdjunt',
  
    data() {
      return {
        list_tasks: [],
        proyecto_id_seleccionado: '',
        id_task: '',
        tarea: '',
        list_adjunts: [],
        pagination: [],
        paginas_totales: 0,
        pagina_actual: 1,
        nombre_buscar: '',
        server: 'http://127.0.0.1:8000/',
        }
      },

    mounted() {
        this.proyecto_id_seleccionado = this.$route.params.id_proyecto;
        this.id_task = this.$route.params.id_tarea;
        this.listar_tareas(this.pagina_actual)
        this.listar_adjuntos_tarea(this.pagina_actual)
        
      },
    methods: {
      listar_tareas () {
        TasksServices.tareasListado({
            options:'list_total',
            id_project: this.proyecto_id_seleccionado, 
          }).then(response => {
          this.list_tasks =  response.data.listado
          this.tarea = this.list_tasks.find(item => item.id == this.id_task)
        })
      },
      listar_adjuntos_tarea (pagina) {
        this.pagina_actual = pagina
        AdjuntsServices.adjuntosListado({
          options:'list',
          id_task: this.id_task,
          page: pagina,
        }).then(response => {
          this.list_adjunts =  response.data.listado
          this.paginas_totales = response.data.paginacion.length
          this.pagination = [...Array(this.paginas_totales).keys()];
        })
      },
      crear_adjunt_task (id_task) {
        this.$router.push({
          name: "CrearAdjunto",
          params:{
            id_tarea: id_task,
            id_proyecto: this.proyecto_id_seleccionado,
          }
        });
      },
      editar_adjunt (id) {
      this.$router.push({
          name: "EditarAdjunto",
          params:{
            id_tarea: this.id_task,
            id_proyecto: this.proyecto_id_seleccionado,
            id_adjunto: id
          }
        });
      },
      listar_adjunts (id_task) {
        this.$router.push({
          name: "AdjuntosTarea",
          params:{
            id_tarea: id_task,
            id_proyecto: this.proyecto_id_seleccionado,
          }
        });
      },
      getName(url){
        if(url.indexOf("/") !== -1){
          var name_file = url.split("/");
          return name_file[1]
        }
        else if(url.indexOf("\\") !== -1){
          var name_file2 = url.split("\\");
          return name_file2[1]
        }
      },
      project_tasks (id_project) {
        this.$router.push({
          name: "TareaProyecto",
          params:{
            id: id_project,
          }
        });
      },
      delete_adjunt (id) {
        this.$swal({
          title: "¿Seguro que desea eliminar el adjunto seleccionado?",
          text: "¡Una vez eliminado, no podrás recuperar el archivo del registro correspondiente!",
          icon: "warning",
          showDenyButton: true,
          denyButtonText: `Cancelar`,
          confirmButtonText: 'Eliminar',
          customClass: {
            actions: 'my-actions',
            denyButton: 'order-2',
            confirmButton: 'order-3',
          }
        }).then((result) => {
          if (result.isConfirmed) {
            AdjuntsServices.eliminarAdjunto({
            options:'delete',
            id_adj: id})
            .then(response => {
              this.$swal({
                title: "¡Buen trabajo!",
                text: response.data.message,
                icon: "success",});
              this.list_adjunts = []
              this.listar_adjuntos_tarea(1)
            })
            
          }
        })
      } 
    },
    props: {
      msg: String
    }
    }

  const statuses = { Completed: 'text-green-400 bg-green-400/10', Error: 'text-rose-400 bg-rose-400/10' }
  

  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  </style>
  