<template>
    <div>
        
        <div class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
            <div class="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
                <div class="flex h-16 shrink-0 items-center">
                <img class="h-8 w-auto" src="@/assets/logohupressusNew.png" alt="logo_hupressus"/>
                </div>
                <nav class="flex flex-1 flex-col">
                    <ul role="list" class="flex flex-1 flex-col gap-y-7">
                        <li>
                            <ul role="list" class="-mx-2 space-y-1">
                                <li v-for="item in Home" :key="item.name">
                                    <router-link
                                        :to="item.href"
                                        :class="[
                                        item.href === $route.path
                                            ? 'bg-gray-50 text-purple-700'
                                            : 'text-gray-700 hover:text-purple-700 hover:bg-gray-50',
                                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                        ]"
                                    >
                                        <i :class="item.icon" style='font-size:20px;'></i>
                                        {{ item.name }}
                                    </router-link>
                                </li>
                                <li>
                                    <button class="block rounded-md bg-transparent px-3 py-1.5 text-sm font-semibold leading-6 text-gray-700 shadow-sm hover:bg-gray-50 hover:text-purple-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-700" @click="logout()" v-if="token">Cerrar sesión</button>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
        <TransitionRoot as="template" :show="sidebarOpen">
            <Dialog as="div" class="relative z-50 " @close="sidebarOpen = false"> <!--class= lg:hidden-->
                <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 bg-gray-900/80" />
                </TransitionChild>

                <div class="fixed inset-0 flex">
                <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
                    <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
                    <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
                        <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
                        <button style=" position: absolute; left: -2rem; color: black; font-size: 15px; font-family: initial;" type="button" class="-m-2.5 p-2.5" @click="sidebarOpen = false"> X
                            <span class="sr-only">Close sidebar</span>
                        </button>
                        </div>
                    </TransitionChild>
                    <!--Sidebar component, swap this element with another sidebar if you like -->
                    <div class="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
                        <div class="flex h-16 shrink-0 items-center">
                        <div class="border-b-8 border-b-redppa w-3/4">
                            <h2 class="mt-4 mb-0 text-xl font-extrabold leading-0 tracking-tight text-gray-900 border-b-4 border-b-goldppa pb-2">Hupressus</h2>
                        </div>
                        </div>
                        <nav class="flex flex-1 flex-col">
                        <ul role="list" class="flex flex-1 flex-col gap-y-7">
                            <li>
                            <ul role="list" class="-mx-2 space-y-1">
                                <li @click="pageIr()" v-for="item in Home" :key="item.name">
                                    <router-link
                                        :to="item.href"
                                        :class="[
                                        item.href === $route.path
                                            ? 'bg-gray-50 text-purple-700'
                                            : 'text-gray-700 hover:text-purple-700 hover:bg-gray-50',
                                        'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                        ]"
                                    >
                                        <i :class="item.icon" style='font-size:20px;'></i>
                                        {{ item.name }}
                                    </router-link>
                                </li>
                            </ul>
                            </li>
                            <li class="mt-auto">
                                <div class="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50 cursor-pointer" @click="logout() " v-if="token">
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9" />
                                        </svg>

                                    </div>
                                    Cerrar Sesión
                                </div>
                            </li>
                        </ul>
                        </nav>
                    </div>
                    </DialogPanel>
                </TransitionChild>
                </div>
            </Dialog>
        </TransitionRoot>
        <div class="lg:pl-72">
            <div class="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
                <button type="button" class="-m-2.5 p-2.5 text-gray-700 lg:hidden" @click="sidebarOpen = true">
                    <span class="sr-only">Open sidebar</span>
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
                    </svg>
                </button>
                <div class="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true" />
                    <div class="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
                        <div class="flex flex-1"/>
                        <div class="flex items-center gap-x-4 lg:gap-x-6">
                        <div class="hidden lg:block lg:h-6 lg:bg-gray-200" aria-hidden="true" />
                        <Menu as="div" class="font-bold relative">
                            <p>Hupressus. All rights reserved. Copyright 2024.</p>
                        </Menu>
                    </div>
                </div>
            </div>
            <main class="py-10">
                <div class="px-4 sm:px-6 lg:px-8">
                <!-- Your content -->
                <router-view />
                </div>
            </main>
        </div>
    </div>
</template>
<script>
import { ref } from 'vue'
import AccountsServices from '@/services/accounts/accounts'
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import {
    Dialog,
    DialogPanel,
    DialogTitle,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue'

export default { 
    
    name: "Home",
    components: {
        Dialog,
        DialogPanel,
        DialogTitle,
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        TransitionChild,
        TransitionRoot,
    },
    // setup() {
    //     const sidebarOpen = ref(false);
    //     return {
    //         sidebarOpen,
    //     };
    // },
    data () {
        
        return {
            
            Home: [
                { name: 'Inicio', href: '/', icon:'fa fa-home text-gray-500', tipo:[1] },
                { name: 'Administrador', href: '/administradores', icon:'fa fa-user-circle text-gray-500', tipo:[1]},
                { name: 'Clientes', href: '/clientes', icon:'fa fa-user text-gray-500', tipo:[1 ]},
                { name: 'Empresas', href: '/companies', icon:'fa fa-building text-gray-500', tipo:[1 ] },
                { name: 'Proyectos', href: '/projects', icon:'fa fa-book text-gray-500', tipo:[1,2,3 ] },
                { name: 'Solicitar APK', href: '/solicitarAPK', icon:'fa fa-file-alt text-gray-500'  , tipo:[1,3] },
                { name: 'Actualizar Repositorio ', href: '/updateprollect', icon:'fa fa-tachometer-alt text-gray-500' , tipo:[1] },
                { name: 'Cuentas Restringidas  ', href: '/CuentasAdmin', icon:'fa fa-lock text-gray-500', tipo:[1] },
                { name: 'Actualizar Repositorios  ', href: '/listrepo', icon:'fa fa-tachometer-alt text-gray-500',tipo:[3 ]  },
                { name: 'Templates', href: '/templates_list', icon:'fa fa-life-ring text-gray-500',tipo:[1,3 ]  },
                // { name: 'Tareas', href: '/tasks', icon:'fa fa-check-square-o text-gray-500'},
            ],
            teams: [
                { id: 1, name: 'Heroicons', href: '#', initial: '', current: false },
                { id: 2, name: 'Tailwind Labs', href: '#', initial: '', current: false },
                { id: 3, name: 'Workcation', href: '#', initial: '', current: false },
            ], 
            userNavigation: [
                { name: 'Your profile', href: '#' },
                { name: 'Sign out', href: '#' },
            ],  
            sidebarOpen: false,
            token: '',
            usuario: '',
            tipoUsuario: '',
            nombre_buscar: '',
            filteredHome: [],
            typeAccount: ''
        }
    },
    created() {
    },
    mounted() {
        this.token = localStorage.getItem('token_auth');
        this.usuario = localStorage.getItem('user_auth');
        this.typeAccount = parseInt( localStorage.getItem('type_account'));
        // this.listaCompanias();
        // this.listar_administradores();
        // this.filterMenuItems();
        console.log('Vuex State:', this.$store.state);
        this.tipoUsuario = this.typeAccount;
        console.log('Tipo de Usuario:', this.tipoUsuario);
        this.filtrarNavigation();
    },
    methods: {
        // filterMenuItems() {
        //     if (this.tipoUsuario !== 'admin' && this.tipoUsuario !== 'client') {
        //         this.filteredHome = this.Home.filter(item => item.name !== 'Cuentas Restringidas');
        //     } else {
        //         this.filteredHome = this.Home;
        //     }
        //     console.log(this.filteredHome);
        // },
        pageIr(){
            this.sidebarOpen = false
        },

        logout() {
            localStorage.clear();
            sessionStorage.clear();
            AccountsServices.cerrarSesion({
            })
            .then(response => {
                this.$swal({
                    title: "¡Buen trabajo!",
                    text: response.data.message,
                    icon: "info",});
                this.$router.push({name: "Login"}); 
            })
        },
        // listaCompanias () {
        //     CompaniesServices.empresasListado({
        //     options:'list_total', 
        //     }).then(response => {
        //     this.lista_companias =  response.data.listado
        //     console.log(this.lista_companias);
        //     })
        // },
        // listar_administradores(pagina) {
        //     this.pagina_actual = pagina
        //     const datasend = {
        //     consulta: this.nombre_buscar,
        //     }
        //     administradorServices.listarprogramadores(datasend)
        //     .then((data) => {
        //     this.list_companies = data.data.listado
        //     console.log(data)
        //     })
        // },
        filtrarNavigation() {
            console.log('tipo de usuario', this.tipoUsuario);
            this.Home = this.Home.filter(item => {
                if (item.tipo.includes(this.tipoUsuario)) {
                    return item;
                }
            });
            console.log(this.Home)
        }
    }
}
</script>