<template>
    <div>
      <form v-on:submit.prevent= "create_company();">
        <div class="space-y-12 sm:space-y-16">
          <div>
            <h1 class="text-center text-2xl font-semibold leading-7 text-gray-900">Crear Proyecto </h1>
            <p class="w-1/3 my-8 m-auto p-1 text-left text-sm leading-1 text-gray-600">Registra los datos del proyecto.</p>
            <div class="container w-3/5 m-auto p-3 rounded">
              <div class="relative float-label-input">
                <input type="text" id="name" name="name" autocomplete="name" placeholder="" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="project.nombre" />
                <label for="name" class="absolute top-3 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white px-2 text-grey-darker">Nombre del proyecto</label>
                <span class="block bg-white w-full focus:outline-none focus:shadow-outline border border-red-500 rounded-md py-1 px-1 appearance-none leading-normal" v-if="submited && !$v.project.nombre.required">El campo nombre es obligatorio.</span>
              </div>

              <div class="relative float-label-input">
                <select id="company" name="company" autocomplete="company-name" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="project.empresaId">
                  <option v-for="item in list_companies" :value= "item.id">{{ item.name }}</option>
                </select>
                <label for="company" class="absolute top-3 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white px-2 text-grey-darker">Seleccione una empresa</label>
                <span class="block bg-white w-full focus:outline-none focus:shadow-outline border border-red-500 rounded-md py-1 px-1 appearance-none leading-normal" v-if="submited && !$v.project.empresaId.required">Seleccione una empresa.</span>
              </div>

              <div class="relative float-label-input">
                <button type="button" class="text-sm font-semibold leading-6 text-gray-900 px-1.5 py-1.5 rounded-md border border-gray-950" style="margin: 1em;"><router-link to="/projects">Cancelar</router-link><router-view /></button>
                <button type="submit" class="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" style="margin: 1em;">Agregar Proyecto</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </template>
  
  <script>
  import ProjectsServices from '@/services/projects/projects'
  import CompaniesServices from '@/services/companies/companies'
  import { required } from '@vuelidate/validators';
  import useVuelidate from '@vuelidate/core';
  export default {
    name: 'CreateProject',
    data() {
      return {
        submited: false,
        list_companies: [],
        project: {
          nombre: '',
          empresaId: '',
        },
      }
    },
    mounted() {
      this.listar_empresas()
    },
    setup() {
      return { v$: useVuelidate() };
    },
    methods: {
      listar_empresas () {
        CompaniesServices.empresasListado({
            options:'list_total', 
          }).then(response => {
          this.list_companies =  response.data.listado
        })
      },
      create_company () {
        this.v$.$touch();
        if (this.v$.$invalid) {
          console.log('Formulario no válido');
          return;
        }
        ProjectsServices.crearProyecto({
          options:'new',
          name : this.project.nombre,
          id : this.project.empresaId,          
        })
        .then(response => {
        this.$swal({
           title: "¡Buen trabajo!",
           text: response.data.message,
           icon: "success",});
        this.$router.push("/projects");
        })
      },
    },
    validations: {
        project: {
          nombre:{
            required
          },
          empresaId:{
            required
          }
      },
    },
    props: {
      msg: String
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: black;
  }

  .float-label-input {
    margin: 32px 0;     
  }

  .float-label-input:focus-within label, .float-label-input input:not(:placeholder-shown) + label {
    transform: translateY(-1.5rem) scale(0.75);
    background-color: white;
  }
  .float-label-input select:not(:placeholder-shown) + label {
    transform: translateY(-1.5rem) scale(0.75);
    background-color: white;
  }
  </style>
  