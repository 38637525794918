import { axiosInstance } from '@/boot/axios';

export default {
    crearAdministrador (data) {
        return axiosInstance.post('/create_account', data);
    },
    crearAdministrador (data) {
        return axiosInstance.post('/create_account', data);
    },
    listarAdministradores (data) {
        return axiosInstance.post('/list_account', data);
    },
    listarprogramadores (data) {
        return axiosInstance.post('/list_account_programadores', data);
    },
    deleteAdministrador (data) {
        return axiosInstance.post('/delete_account', data)
    },
    editarAdministrador (data) {
        return axiosInstance.post('/info_account', data)
    },
    editAdmin (data) {
        return axiosInstance.post('/edit_admin', data)
    },
    obtenertipeuser (data) {
        return axiosInstance.post('/check_account_type', data);
    },
    get_repos (data) {
        return axiosInstance.post('/get_repos_ids', data);
    },
}
