// import Vue from 'vue'
// import App from './App.vue'
import Store from '@/store';
import Cookies from 'js-cookie'
import { axiosInstance } from '@/boot/axios';

import Home from '@/layouts/Home'
import Login from '@/views/Login'
import RouterView from '@/views/index.vue';

import ListCompanies from '@/views/companies/ListCompanies'
import CreateCompany from '@/views/companies/CreateCompany'
import EditCompany from '@/views/companies/EditCompany'

import ListProjects from '@/views/projects/ListProjects'
import CreateProject from '@/views/projects/CreateProject'
import EditProject from '@/views/projects/EditProject'

import solicitarAPKS from '@/views/solicitarAPK/solicitarAPKS'

import prollectupdate from '@/views/updateprollect/prollectupdate'
import createrepo from '@/views/updateprollect/createrepo'
import Editrepo from '@/views/updateprollect/Editrepo'
import create_user from '@/views/updateprollect/create_user'

import cuentasadmin from '@/views/CuentasAdmin/cuentasadmin'
import restringir from "@/views/CuentasAdmin/restringir"

import listrepo from '@/views/ListRepo/listrepo'

// import templatelist from '@/views/template/templatelist'
// import createtemplate from '@/views/template/createtemplate'
// import Edittemplate from '@/views/template/Edittemplate'

import ListTasks from '@/views/tasks/ListTasks'
import SelectedTask from '@/views/tasks/SelectedTask'
import CreateTask from '@/views/tasks/CreateTask'
import EditTask from '@/views/tasks/EditTask'

import ListAdjunts from '@/views/adjunts/ListAdjunts'
import SelectedAdjunt from '@/views/adjunts/SelectedAdjunt'
import CreateAdjunt from '@/views/adjunts/CreateAdjunt'
import EditAdjunt from '@/views/adjunts/EditAdjunt'

import ListRemarks from '@/views/remarks/ListRemarks'
import SelectedRemark from '@/views/remarks/SelectedRemark'
import CreateRemark from '@/views/remarks/CreateRemark'
import EditRemark from '@/views/remarks/EditRemark'

import AdministradorCreate from "@/views/administradores/CrearAdministrador"
import AdministradorEdit from "@/views/administradores/EditAdministrador"
import AdministradorList from  "@/views/administradores/ListAdministrador"

import ClientesCreate from '@/views/clientes/CreateClientes'
import ClientesEdit from '@/views/clientes/EditClientes'
import ClientesList from  '@/views/clientes/ListClientes'

import TemplatesList from '@/views/templat/ListTemplates'
import TemplatesEdit from '@/views/templat/EditTemplate'
import TemplatesCreate from '@/views/templat/CreateTemplates'

import AccountsServices from '@/services/accounts/accounts'

import StatusHistory from "@/views/status_history/StatusHistory"

import Swal from 'sweetalert2';

const routes=[
    {
        path: "/login",
        component: Login,
        name: 'Login',
        beforeEnter: async (to, from, next) => {
            if (!localStorage.getItem('token_auth') && to.name !== 'Login') {
                next('/login');
            } else {
                // console.log(double_auth, 'respuesta de la doble auntentifiacion')
                // const double_auth = await double_authentication()
                // if(double_auth) {
                // next();
                // } else {
                // next('/login');
                // }
                next();
            }
        }
    },
    {
        path: '/',
        name:'principal',
        component: Home,
        beforeEnter: async (to, from, next) => {
            const ckj = Cookies.get('tkn');
            if (ckj) {
                axiosInstance.defaults.headers.common = {
                    Accept: 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    Authorization: ckj
                };
            }
            // const tipoUsuario = Store.state.user.type;

            // if ([0].includes(tipoUsuario)) {
            //     Swal.fire({
            //         icon: 'error',
            //         title: 'No tienes los privilegios para acceder a esta página'
            //     });
            //     next('/inspecciones');
            // }

            if (!localStorage.getItem('token_auth') && to.name !== 'Login') {
                next('/login');
            } else {
                const double_aut = await double_authentication()
                console.log(double_aut, 'respuesta a la doble verifiación')
                if(double_aut) {
                    if (parseInt(localStorage.getItem('type_account')) === 1 && to.path === '/'){
                        next('/administradores');
                    }else if (parseInt(localStorage.getItem('type_account')) === 2 && to.path === '/') {
                        next('/projects');
                    }else if (parseInt(localStorage.getItem('type_account')) === 3 && to.path === '/'){
                        next('/listrepo');
                    }else{
                        next();
                    }
                } else {
                    next('/login');
                }
            }
        },
        children: [
            {
                path: "",
                name:'principal2',
                component: AdministradorList,
            },
            {                                           // ADMINISTRADORES
                path: "administradores",
                component: RouterView,
                beforeEnter: (to, from, next) => {
                    if (parseInt(localStorage.getItem('type_account')) === 1){
                        next();
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'No tienes los privilegios para acceder a esta página'
                        });
                        if (parseInt(localStorage.getItem('type_account')) === 2){
                            next('/projects');
                        } else {
                            next('/listrepo');
                        }
                    }
                },
                children: [
                    {
                        path: "",
                        name: "administradorList",
                        component: AdministradorList,
                    },
                    {
                        path: "create",
                        name: "administradorCreate",
                        component: AdministradorCreate,
                    },
                    {
                        path: "edit/:id",
                        name: "administradorEdit",
                        props: true,
                        component: AdministradorEdit,
                    },
                ]
            },
            {                                           // CLIENTES
                path: '/clientes',
                component: RouterView,
                beforeEnter: (to, from, next) => {
                    if (parseInt(localStorage.getItem('type_account')) === 1){
                        next();
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'No tienes los privilegios para acceder a esta página'
                        });
                        if (parseInt(localStorage.getItem('type_account')) === 2){
                            next('/projects');
                        } else {
                            next('/listrepo');
                        }
                    }
                },
                children: [
                    {
                        path:'',
                        name: 'clientesList',
                        component: ClientesList,
                    },
                    {
                        path: 'create',
                        name: 'clientesCreate',
                        component: ClientesCreate,
                    },
                    {
                        path: 'edit/:id',
                        props: true,
                        name:'clientesEdit',
                        component: ClientesEdit,
                    }
                ]
            },
            {                                           // EMPRESAS
                path: '/companies',
                component: RouterView,
                beforeEnter: (to, from, next) => {
                    if (parseInt(localStorage.getItem('type_account')) === 1){
                        next();
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'No tienes los privilegios para acceder a esta página'
                        });
                        if (parseInt(localStorage.getItem('type_account')) ===2) {
                            next('/projects');
                        } else {
                            next('/listrepo');
                        }
                    }
                },
                children: [
                    {
                        path: '',
                        name: 'companies',
                        component: ListCompanies,
                    },
                    {
                        path:'create',
                        props:true,
                        name:'CrearEmpresa',
                        component: CreateCompany,
                    },
                    {
                        path: 'edit/:id',
                        props: true,
                        name: 'EditarEmpresa',
                        component: EditCompany,
                    },
                ],
            },
            {                                           // PROYECTOS
                path: '/projects',
                component: RouterView,
                children: [
                    {
                        path: '',
                        name: 'projects',
                        component: ListProjects,
                    },
                    {
                        path:'create',
                        name:'CrearProyecto',
                        component: CreateProject,
                    },
                    {
                        path: 'edit/:id',
                        props: true,
                        name: 'EditarProyecto',
                        component: EditProject,
                    },
                ],
            },
            {                                           // update
                path: '/solicitarAPK',
                component: RouterView,
                beforeEnter: (to, from, next) => {
                    if (parseInt(localStorage.getItem('type_account')) === 1 || parseInt(localStorage.getItem('type_account')) === 3){
                        next();
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'No tienes los privilegios para acceder a esta página'
                        });
                        next('/projects');
                    }
                },
                children: [
                    {
                        path: '',
                        name: 'solicitarAPK',
                        component: solicitarAPKS,
                    }
                ],
            },
            {                                           // update
                path: '/updateprollect',
                component: RouterView,
                beforeEnter: (to, from, next) => {
                    if (parseInt(localStorage.getItem('type_account')) === 1){
                        next();
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'No tienes los privilegios para acceder a esta página'
                        });
                        if (parseInt(localStorage.getItem('type_account')) ===2) {
                            next('/projects');
                        } else {
                            next('/listrepo');
                        }
                    }
                },
                children: [
                    {
                        path: '',
                        name: 'updateprollect',
                        component: prollectupdate,
                    },
                    {
                        path:'create',
                        name:'CrearRepo',
                        component: createrepo,
                    },
                    {
                        path: 'edit/:id',
                        props: true,
                        name: 'Editarrepo',
                        component: Editrepo,
                    },
                    {
                        path: 'createusr',
                        name: 'create_user',
                        component: create_user,
                    },
                ],
            },
            {                                           // update
                path: '/CuentasAdmin',
                component: RouterView,
                beforeEnter: (to, from, next) => {
                    if (parseInt(localStorage.getItem('type_account')) === 1){
                        next();
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'No tienes los privilegios para acceder a esta página'
                        });
                        if (parseInt(localStorage.getItem('type_account')) ===2) {
                            next('/projects');
                        } else {
                            next('/listrepo');
                        }
                    }
                },
                children: [
                    {
                        path: '',
                        name: 'CuentasAdmin',
                        component: cuentasadmin,
                    },
                    {
                        path: "edit/:id",
                        name: "restringir",
                        props: true,
                        component: restringir,
                    },
                ],
            },
            {                                           // update
                path: '/ListRepo',
                component: RouterView,
                beforeEnter: (to, from, next) => {
                    if (parseInt(localStorage.getItem('type_account')) === 3){
                        next();
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'No tienes los privilegios para acceder a esta página'
                        });
                        if (parseInt(localStorage.getItem('type_account')) === 2){
                            next('/projects');
                        } else{
                            if (parseInt(localStorage.getItem('type_account')) === 1) {
                                next('/administradores')
                            }
                        }
                    }
                },
                children: [
                    {
                        path: '',
                        name: 'ListRepo',
                        component: listrepo,
                    },
                ],
            },
            // {                                           // template
            //   path: '/template',
            //   component: RouterView,
            //   children: [
            //     {
            //       path: '',
            //       name: 'template',
            //       component: templatelist,
            //     },
            //     {
            //       path:'create',
            //       name:'Creartemplate',
            //       component: createtemplate,
            //     },
            //     {
            //       path: 'edit/:id',
            //       props: true,
            //       name: 'Editartemplate',
            //       component: Edittemplate,
            //     },
            //   ],
            // },
            {                                           // TAREAS
                path: '/tasks',
                component: RouterView,
                children: [
                    {
                        path: '',
                        name: 'tasks',
                        component: ListTasks,
                    },
                    {
                        path: ':id',
                        name: 'TareaProyecto',
                        props: true,
                        component: SelectedTask,
                    },
                    {
                        path: 'create',
                        name: 'CrearTarea',
                        component: CreateTask,
                    },
                    {
                        path: 'edit/:id',
                        name: 'EditarTarea',
                        props: true,
                        component: EditTask,
                    },
                ],
            },
            {                                           // ADJUNTOS DE TAREA
                path: '/adjunts_tasks',
                component: RouterView,
                children: [
                    {
                        path: '',
                        name: 'adjuntos_tasks',
                        component: ListAdjunts,
                    },
                    {
                        path: 'adjuntos/project:id_proyecto/task:id_tarea',
                        name: 'AdjuntosTarea',
                        props: true,
                        component: SelectedAdjunt,
                    },
                    {
                        path: 'create/project:id_proyecto/task:id_tarea',
                        name: 'CrearAdjunto',
                        component: CreateAdjunt,
                    },
                    {
                        path: 'edit/project:id_proyecto/task:id_tarea',
                        name: 'EditarAdjunto',
                        props: true,
                        component: EditAdjunt,
                    },
                ],
            },
            {                                           // OBSERVACIONES DE TAREA
                path: '/remarks_tasks',
                component: RouterView,
                children: [
                    {
                        path: '',
                        name: 'observaciones_tasks',
                        component: ListRemarks,
                    },
                    {
                        path: 'notas/project:id_proyecto/task:id_tarea',
                        name: 'ObservacionesTarea',
                        props: true,
                        component: SelectedRemark,
                    },
                    {
                        path: 'create/project:id_proyecto/task:id_tarea',
                        name: 'CrearObservacion',
                        component: CreateRemark,
                    },
                    {
                        path: 'edit/project:id_proyecto/task:id_tarea',
                        name: 'EditarObservacion',
                        props: true,
                        component: EditRemark,
                    },
                ],
            },
            {                                           // HISTORIAL DE STATUS DE TAREA
                path: '/status_history_tasks/project:id_proyecto/task:id_tarea',
                component: RouterView,
                children: [
                    {
                        path: '',
                        name: 'history_status_tasks',
                        props: true,
                        component: StatusHistory,
                    }
                ],
            },
            {                                           // Templates
                path: '/templates_list',
                component: RouterView,
                beforeEnter: (to, from, next) => {
                    if (parseInt(localStorage.getItem('type_account')) === 1 || parseInt(localStorage.getItem('type_account')) === 3){
                        next();
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'No tienes los privilegios para acceder a esta página'
                        });
                        next('/projects');
                    }
                },
                children: [
                    {
                        path: '',
                        name: 'templates',
                        component: TemplatesList,
                    },
                    {
                        path: 'crear-template/',
                        name: 'creartemplates',
                        component: TemplatesCreate,
                    },
                    {
                        path: 'edit-template/:id_template',
                        name: 'edithtemplates',
                        props: true,
                        component: TemplatesEdit,
                    }
                ],
            },
        ]
    }
]

export default routes
/*
const router = new VueRouter({
  mode: "history",
  routes,
})

router.beforeEach((to, from, next) => {
  if (!localStorage.getItem('token_auth') && to.name !== 'Login'){
    next('/login');
  }
  else{
    if(double_authentication()){
      next();
    } else {
      next('/login');
    }
  }
});
*/
async function double_authentication () {
  const response = await AccountsServices.verificarToken({
    'token': localStorage.getItem('token_auth'),
    'user_id': localStorage.getItem('user_id')
  })
    return response.data.verification_2
}


// new Vue({
//   render: h => h(App),
//   router,
// }).$mount('#app')