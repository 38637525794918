<template>
    <fieldset>
        <legend class="text-sm font-semibold leading-6 text-gray-900">repositorios disponibles</legend>
        <thead class="bg-gray-50">
          <tr>
              <th style="    width: 20vw;"  scope="col" class="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6"></th>
              <th style="    width: 15vw;" scope="col" class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">Nombre</th>
              <th style="    width: 15vw;" scope="col" class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">Repositorio</th>
              <th style="    width: 15vw;"scope="col" class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">Estado</th>
              <th style="    width: 20vw;" scope="col" class="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6"></th>
          </tr>
        </thead>
        <div class="mt-6 space-y-6">
            <div style="display: flex; justify-content: center;" v-for="item in list_projects" :key="item.id"  class="relative flex gap-x-3">
                <div class="flex h-6 items-center">
                    <input v-bind="$attrs" :id="'checkbox-' + item.id" :name="'checkbox-' + item.id" type="checkbox" class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" v-model="selectedCheckboxes" :value="item.id"/>
                </div>
                <div  class=" newclas text-sm leading-6">
                    <div class="flexclass">
                        <label :for="'checkbox-' + item.id" class="font-medium text-gray-900">{{ item.proyecto }}</label>
                    </div>
                    <div class="flexclass">
                        <p class="text-gray-500">{{ item.repositorio }}</p>
                    </div>
                    <div class="flexclass">
                        <p class="text-gray-500">{{ item.estado ? item.estado : 'Sin estatus reciente' }}</p>
                    </div>
                </div>
            </div>
        </div>
    </fieldset>
    <div style="display: flex; flex-direction: column-reverse; justify-content: center; " class="mt-6 flex items-center justify-end gap-x-6">
      <button style="width: 10vw; margin: 1rem;" @click="editarregresar" type="button" class="text-sm font-semibold leading-6 text-gray-900">Cancel</button>
      <button  style="width: 10vw;" type="submit" @click="saveSelected()" class="rounded-md bg-lime-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Marcar como Disponibles</button>
    </div>
</template>
  
<script>
import ProjectsServices from '@/services/projects/projects'
import CompaniesServices from '@/services/companies/companies'
import repositoriosServices from '@/services/repositorios/repositorios'
import administradorServices from '@/services/Administradores/Administradores'
import AccountsServices from '@/services/accounts/accounts'
import { PhotoIcon, UserCircleIcon } from '@heroicons/vue/24/solid'
  export default {
    name: 'ListProjects',
    inheritAttrs: false,
    data() {
      return {
        list_companies: [],
        list_projects: [],
        pagination: [],
        paginas_totales: 0,
        pagina_actual: 1,
        nombre_buscar: '',
        showLogsColumn: false,
        projectIdToDelete: null,
        updatespending: [],
        checkerstatus: null,
        id_usuario: '',
        selectedCheckboxes: [],
        list_repos: [],
        }
      },
    beforeDestroy(){
        clearInterval(this.checkerstatus)
    },
    mounted() {
        this.listar_empresas()
        this.listar_administradores()
        this.repos_list(this.pagina_actual)
        this.checkerstatus = setInterval(() =>{this.checkStatusUpdate()}, 1000)
        this.id_recibido = this.$route.params.id;
        this.email_recibido = this.$route.params.email;
        this.first_name_recibido = this.$route.params.first_name;
        this.get_repositorios()
      },
    methods: {
        get_repositorios() {
          const data = {
            id: this.id_recibido
          }
          administradorServices.get_repos(data)
          .then((response) => {
            this.selectedCheckboxes = response.data.listado
          })
        },
        listar_administradores() {
          const datasend = {
            consulta: this.nombre_buscar
          }
          administradorServices.listarprogramadores(datasend)
          .then((data) => {
            this.list_companies = data.data.listado
            console.log(this.list_companies)
            console.log(data)
          })
        },
        editarregresar () {
            this.$router.push({
            name: "CuentasAdmin"
            });
        },
        saveSelected() { // Verificar el array de IDs antes de enviar
          console.log('usuarioid', this.id_recibido);
          console.log(this.selectedCheckboxes);
          repositoriosServices.crearlist({
              selectedCheckboxes: this.selectedCheckboxes,
              id_user: this.id_recibido // Asegúrate de que `this.id_recibido` contenga el ID correcto
          })
          .then(response => {
              this.$swal({
                  title: "¡Buen trabajo!",
                  text: response.data.message,
                  showConfirmButton: false,
                  timer: 1500,
                  icon: "success",
              });
              this.$router.push("/CuentasAdmin");
          })
          .catch(error => {
              console.error("Error al enviar los datos:", error);
              this.$swal({
                  title: "Error",
                  text: "Hubo un problema al guardar los datos.",
                  icon: "error",
              });
          });
        },
      editar_project (id) {
        this.$router.push({
          name: "Editarrepo",
          params:{
            id: id,
          }
        });
      },
      create_user () {
        this.$router.push({
          name: "create_user",
        });
      },
      obt_ckick(id) {
        // Almacenar el ID del proyecto en la variable projectIdToDelete
        this.projectIdToDelete = id;
        console.log("ID del proyecto almacenado:", this.projectIdToDelete);
      },
      checkStatusUpdate(){
        if(this.updatespending.length > 0){
            repositoriosServices.checkStatusServer(
                        {
                            listpend: JSON.stringify(this.updatespending)
                        }
            ).then(response=>{
                for (var i = response.data.servers.length - 1; i >= 0; i--) {
                    var estado = response.data.servers[i].estado
                    var pos = this.list_projects.findIndex(x => x.id === response.data.servers[i].id)
                    this.list_projects[pos].estado = estado
                    
                    if(estado.includes('Servidor actualizado. Última actualización:') || estado.includes('Error en actualización. Última actualización:')){

                            const index = this.updatespending.indexOf(response.data.servers[i].id);
                            if (index > -1) { // only splice array when item is found
                              this.updatespending.splice(index, 1); // 2nd parameter means remove one item only
                            }
                    }
                }
            })
        }
      },
      updateServer(item){
        repositoriosServices.goUpdateServer(
            {
                id: item
            }
        ).then(response=>{
            this.$swal({
               title: "Actualización solicitada",
               text: "Actualización programada con éxito",
               showConfirmButton: false,
               timer: 1500,
               icon: "success",
           });
            this.updatespending.push(item)
        })
      },
      listar_empresas () {
        repositoriosServices.empresasListado({
            options:'list_total', 
          }).then(response => {
          this.list_companies =  response.data.listado
        })
      },
      repos_list (pagina) {
        this.pagina_actual = pagina
        repositoriosServices.reposListado({
          options:'list',
          page: pagina,
          consulta: this.nombre_buscar,  
        }).then(response => {
          this.list_projects =  response.data.listado
          this.paginas_totales = response.data.paginacion.length
          this.pagination = [...Array(this.paginas_totales).keys()];
          console.log(this.list_projects);
        })
      },
      delete_project (id) {
        console.log(id)
        this.$swal({
          title: "¿Seguro que desea eliminar el repositorio ?",
          text: "¡Una vez eliminado, no podrás recuperar la información del registro!",
          icon: "warning",
          showDenyButton: true,
          denyButtonText: `Cancelar`,
          confirmButtonText: 'Eliminar',
          customClass: {
            actions: 'my-actions',
            denyButton: 'order-2',
            confirmButton: 'order-3',
          }
        }).then((result) => {
          if (result.isConfirmed) {
            console.log(id)
            repositoriosServices.eliminarProyecto({
            options:'delete',
            id: id})
            .then(response => {
              this.$swal({
                title: "¡Buen trabajo!",
                text: response.data.message,
                icon: "success",});
                this.list_projects = []
                this.repos_list(1)
            })
            
          }
        })
      },
       handleAction(action) {
            if (action === "Ver logs") {
                this.showLogsColumn = true;
            } else {
                this.showLogsColumn = false;
            }
       },
      project_tasks (id_project) {
        this.$router.push({
          name: "TareaProyecto",
          params:{
            id: id_project,
          }
        });
      }, 
    },
    props: {
      msg: String,
    }
    }

  const statuses = { Completed: 'text-green-400 bg-green-400/10', Error: 'text-rose-400 bg-rose-400/10' }
  

</script>
  
    <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* Style The Dropdown Button */
.unclass{
    font-size: large;
    font-weight: bold;
}
.flexclass{
    display: flex;
    flex-direction: column;
}
.newclas{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 40vw;
}
.dropbtn {
    background-color: #a4a4a4;
    color: white;
    padding: 5px;
    font-size: 16px;
    border-radius: 10%;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #f1f1f1}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
  position: fixed;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {
  background-color: #888888;;
}
.h3 {
    margin: 40px 0 0;
}
.ul {
    list-style-type: none;
    padding: 0;
}
.li {
    display: inline-block;
    margin: 0 10px;
}
.a {
    color: #ffffff;
}
</style>