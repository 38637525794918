import { axiosInstance } from '@/boot/axios';

export default {
    crearEmpresa (data) {
        return axiosInstance.post('/companies/crud', data);
    },
    empresasListado(data) {
        return axiosInstance.post('/companies/crud', data);
    },
    eliminarEmpresa(data) {
        return axiosInstance.post('/companies/crud', data);
    },
    infoEmpresa(data) {
        return axiosInstance.post('/companies/crud', data);
    },
    editarEmpresa(data) {
        return axiosInstance.post('/companies/crud', data);
    },
    listProjectsCompany(data) {
        return axiosInstance.post('/projects_companies/', data)
    }
}