import { axiosInstance } from '@/boot/axios';
import Cookies from "js-cookie";

export default {
    iniciarSesion(data) {
        return axiosInstance.post('login_user', data);
    },
    async verificarToken(data) {
        return await axiosInstance.post('verify_token', data);
    },
    cerrarSesion(data) {
        return axiosInstance.post('logout_user', data);
    },
    crearCookieUsuario(userLogged) {
        Cookies.set('token_auth', userLogged);
    },
    obtenerCookieUsuario() {
        return Cookies.get('token_auth');
    }
}