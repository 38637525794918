<template>
    <div>
      <div class="px-4 sm:px-6 lg:px-8">
        <div class="sm:flex sm:items-center text-center">
          <h1 class="m-auto text-center font-semibold leading-6 text-gray-900">Listado de observaciones</h1>
        </div>
        <div class="mt-8 flow-root">
          <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8" v-if="!list_remarks.length">
            <div>
              La tarea no tiene observaciones registradas.
            </div>
            <div class=" w-full mt-10">
              <button type="button" class=" justify-end sm:flex-auto rounded-md bg-purple-800 px-3 py-2 text-right text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" @click="crear_remark_task(id_task)">Agregar una observación</button>
            </div>
          </div>
          <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8" v-else>
            <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <!-- <div class="sm:flex-auto">
                <input class="mt-4 sm:mt-0 sm:flex-none bg-white rounded-3xl p-1.5 mx-auto w-5/12 text-xs"  type="text" placeholder="Buscar tarea por nombre ..." v-model="nombre_buscar"/>
                <button type="button" class="  rounded bg-white w-7 px-0.3 ml-1 py-3 text-xs font-semibold text-white shadow-sm hover:bg-gray-200" style="text-align: center; !important" @click="listar_tareas_empresa(pagina_actual);" > <img class="mx-auto" src="@/assets/buscar.png" alt="Icono_buscador" width="50%"/> </button>
              </div> -->
              <p class="mt-2 text-sm text-start text-gray-500">Listado de adjuntos de la tarea:  {{ tarea.title }}</p>
              <div class="sm:flex sm:items-center">
                <div class=" w-full m-8 mt-4 sm:ml-16 sm:mt-0 sm:items-right">
                  <button type="button" class=" justify-end sm:flex-auto rounded-md bg-purple-800 px-3 py-2 text-right text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" @click="crear_remark_task(id_task)">Agregar una observación a la tarea</button>
                </div>
              </div>
              <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table class="min-w-full divide-y divide-gray-300">
                  <thead class="bg-gray-50">

                    <tr>
                      <th scope="col" class="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6">Observaciones</th>
                      <th scope="col" class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">Autor</th>
                      <th scope="col" class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">Hora y fecha de creación</th>
                      <th scope="col" class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">Acciones</th>
                    </tr>
                  </thead>
                  <tbody class="divide-y divide-gray-200 bg-white">
                    <tr v-for="item in list_remarks" :key="item.id">
                      <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{{ item.text_rmrk }}</td>
                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ item.author_rmrk__username }}</td>
                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ item.datatime_rmrk_str }}</td>
                      <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <button type="button" class="rounded bg-gray-900 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 margin: 1em;" style="margin: 1em;" @click="editar_remark(item.id)">Editar</button>

                        <button type="button" class="rounded bg-purple-400 px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" style="margin: 1em;" @click="delete_remark(item.id)" >Eliminar</button>

                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6" v-if="paginas_totales>1">
        <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
          <div class="mx-auto items-center text-center">
            <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
              <span @click="listar_observaciones_tarea(pagina_actual - 1);">
                <button class="text-xs relative z-10 inline-flex items-center bg-white border border-gray-400 px-4 py-2 font-semibold text-indigo-600 focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ml-1" v-if="pagina_actual - 1 > 0"> Anterior</button>
                <span v-else></span>
              </span>
              <span v-for="pagina in pagination" :key="pagina" @click="listar_observaciones_tarea(pagina + 1);">
                <button class="text-xs relative z-10 inline-flex items-center bg-indigo-500 border border-indigo-500 px-4 py-2 font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" v-if="pagina_actual == pagina + 1">{{ pagina + 1 }}</button>
                <button class="text-xs relative z-10 inline-flex items-center bg-white border border-gray-400 px-4 py-2 font-semibold text-indigo-600 focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"  v-else>{{ pagina + 1 }}</button>
              </span>

              <span @click="listar_observaciones_tarea(pagina_actual + 1);">
              <button class=" text-xs relative z-10 inline-flex items-center bg-white border border-gray-400 px-4 py-2 font-semibold text-indigo-600 focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" v-if="pagina_actual < paginas_totales"> Siguiente</button>
              <span v-else></span>
              </span>
            </nav>
          </div>
        </div>
      </div>
      <div class=" w-full m-auto">
        <button type="button" class="my-8 justify-end sm:flex-auto rounded-md  bg-white px-3 py-1 text-right text-sm font-semibold text-purple-400 shadow-sm hover:bg-purple-800 hover:text-white" @click="project_tasks(proyecto_id_seleccionado)">Ver tareas del proyecto</button>
      </div>
    </div>
  </template>
  
  <script>
  import RemarksServices from '@/services/remarks/remarks'
  import TasksServices from '@/services/tasks/tasks'


  export default {
    name: 'SelectedRemark',
  
    data() {
      return {
        list_tasks: [],
        proyecto_id_seleccionado: '',
        id_task: '',
        tarea: '',
        list_remarks: [],
        pagination: [],
        paginas_totales: 0,
        pagina_actual: 1,
        nombre_buscar: '',
        }
      },

    mounted() {
        this.proyecto_id_seleccionado = this.$route.params.id_proyecto;
        this.id_task = this.$route.params.id_tarea;
        this.listar_tareas(this.pagina_actual)
        this.listar_observaciones_tarea(this.pagina_actual)
        
      },
    methods: {
      listar_tareas () {
        TasksServices.tareasListado({
            options:'list_total',
            id_project: this.proyecto_id_seleccionado, 
          }).then(response => {
          this.list_tasks =  response.data.listado
          this.tarea = this.list_tasks.find(item => item.id =  this.id_task)
        })
      },
      listar_observaciones_tarea (pagina) {
        this.pagina_actual = pagina
        RemarksServices.observacionesListado({
          options:'list',
          id_task: this.id_task,
          page: pagina,
        }).then(response => {
          this.list_remarks =  response.data.listado
          this.paginas_totales = response.data.paginacion.length
          this.pagination = [...Array(this.paginas_totales).keys()];
        })
      },
      crear_remark_task (id_task) {
        this.$router.push({
          name: "CrearObservacion",
          params:{
            id_tarea: id_task,
            id_proyecto: this.proyecto_id_seleccionado,
          }
        });
      },
      editar_remark (id) {
      this.$router.push({
          name: "EditarObservacion",
          params:{
            id_tarea: this.id_task,
            id_proyecto: this.proyecto_id_seleccionado,
            id_observacion: id
          }
        });
      },
      listar_remarks (id_task) {
        this.$router.push({
          name: "ObservacionesTarea",
          params:{
            id_tarea: id_task,
            id_proyecto: this.proyecto_id_seleccionado,
          }
        });
      },
      project_tasks (id_project) {
        this.$router.push({
          name: "TareaProyecto",
          params:{
            id: id_project,
          }
        });
      },
      delete_remark (id) {
        this.$swal({
          title: "¿Seguro que desea eliminar la observación seleccionada?",
          text: "¡Una vez eliminada, no podrás recuperar la información del registro correspondiente!",
          icon: "warning",
          showDenyButton: true,
          denyButtonText: `Cancelar`,
          confirmButtonText: 'Eliminar',
          customClass: {
            actions: 'my-actions',
            denyButton: 'order-2',
            confirmButton: 'order-3',
          }
        }).then((result) => {
          if (result.isConfirmed) {
            RemarksServices.eliminarObservacion({
            options:'delete',
            id_rmrk: id})
            .then(response => {
              this.$swal({
                title: "¡Buen trabajo!",
                text: response.data.message,
                icon: "success",});
              this.list_remarks = []
              this.listar_observaciones_tarea(1)
            })
            
          }
        })
      } 
    },
    props: {
      msg: String
    }
    }

  const statuses = { Completed: 'text-green-400 bg-green-400/10', Error: 'text-rose-400 bg-rose-400/10' }
  

  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #ffffff;
  }
  </style>
  