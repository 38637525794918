<template>
    <div>
      <form v-on:submit.prevent="create_remark()">
        <div class="space-y-12 sm:space-y-16">
          <div>
            <h1 class="text-center text-2xl font-semibold leading-7 text-gray-900">Crear una observación </h1>
            <p class="w-1/3 m-auto p-1 text-left text-sm leading-1 text-gray-600">Registra la información de la observación a generar.</p>
            <div class="container w-1/3 m-auto p-3 rounded">
              <div class="relative float-label-input">
                <textarea id="description" name="description" rows="3" autocomplete="description" class="block w-full bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-md py-3 px-3 appearance-none leading-normal focus:border-blue-400 placeholder:text-center  placeholder:text-gray-400" placeholder="" v-model= "remark"></textarea>
                <label for="description" class="absolute top-3 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white px-2 text-grey-darker">Ingrese su observación</label>
                <span class="block bg-white w-full focus:outline-none focus:shadow-outline border border-red-500 rounded-md py-1 px-1 appearance-none leading-normal" v-if="submited && !$v.remark.required">Ingrese su observación.</span>
              </div>              

              <div class="relative float-label-input">
                <button type="button" class="text-sm font-semibold leading-6 text-gray-900 px-1.5 py-1.5 rounded-md border border-gray-950" style="margin: 1em;" v-if="!proyecto_id_seleccionado"  @click="cancel();">Cancelar</button>
                <button type="button" class="text-sm font-semibold leading-6 text-gray-900 px-1.5 py-1.5 rounded-md border border-gray-950" style="margin: 1em;" v-else @click="listar_remarks(task_id);">Cancelar</button>
                <button type="submit" class="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" style="margin: 1em;" >Agregar Observación</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </template>
  
  <script>
  import RemarksServices from '@/services/remarks/remarks'
  import { required, minLength } from '@vuelidate/validators';

  export default {
    name: 'CreateRemark',
    data() {
      return {
        submited: false,
        task_id: '',
        proyecto_id_seleccionado: '',
        remark: '',
      }
    },
    mounted() {
      this.task_id = this.$route.params.id_tarea;
      this.proyecto_id_seleccionado = this.$route.params.id_proyecto;
    },
    methods: {
      create_remark () {
        if(this.$v.$invalid){
          this.submited = true;
          return false;
        }
        RemarksServices.crearObservacion({
          options:'new',
          remark: this.remark,
          id_task: this.task_id,
          user_id: localStorage.getItem('user_id')
        })
        .then(response => {
          this.$swal({
            title: "¡Buen trabajo!",
            text: response.data.message,
            icon: "success",});
          if(!this.proyecto_id_seleccionado) {
            this.$router.push("/tasks");
          }
          else {
            this.listar_remarks(this.task_id)
          }
        })
      },
      listar_remarks (id) {
        this.$router.push({
          name: "ObservacionesTarea",
          params:{
            id_tarea: id,
            id_proyecto: this.proyecto_id_seleccionado,
          }
        });
      },
      cancel () {
        this.$router.push({
          name: "tasks",
        });
      },
    },
    validations: {
      remark: {
        required,
        minLength: minLength(2)
      },
    },
    props: {
      msg: String
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: black;
  }

  .float-label-input {
    margin: 32px 0;     
  }

  .float-label-input:focus-within label, .float-label-input input:not(:placeholder-shown) + label {
    transform: translateY(-1.5rem) scale(0.75);
    background-color: white;
  }
  </style>
  