<template>
    <div>
      <div class="px-4 sm:px-6 lg:px-8">
        <div class="m-auto text-center">
          <h1 class="m-auto text-center font-semibold leading-6 text-gray-900">Historial de estatus para la tarea:</h1>
          <span class="text-indigo-800 m-auto text-left">{{ tarea.title }}</span>
        </div>
        <div class= "w-min-48 items-end text-right">
          <select id="type" name="type" autocomplete="type-task" class="w- rounded-md bg-purple-400 text-white font-bold my-8 border-0 border-white py-1.5 shadow-sm ring-1 ring-inset ring-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-12" @change="change_status(tarea.id);">
            <option class=" bg-white text-gray-300" selected>Cambiar estatus</option>
            <option class=" bg-white text-black" v-for="id in [...Array(5).keys()]" v-if="id!=tarea.status-1" :value= "(id+1)"> {{ status_values[id+1] }}</option>
          </select>
        </div>

        <div class="mt-8 flow-root">
          <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-300">
                        <thead class="bg-gray-50">
                            <tr>
                            <th scope="col" class="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6">Autor</th>
                            <th scope="col" class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">Estatus actual</th>
                            <th scope="col" class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">Hora y fecha en que se registró el estatus </th>
                            </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-200 bg-white">
                            <tr v-for="item in list_status" :key="item.id">
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ item.author__username }}</td>
                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              <span class="text-purple-400" v-if="item.status == 1">{{ status_values[item.status] }}</span>
                              <span class="text-purple-600" v-else-if ="item.status == 2">{{ status_values[item.status] }}</span>
                              <span class="text-purple-700" v-else-if="item.status == 3">{{ status_values[item.status] }}</span>
                              <span class="text-purple-800" v-else-if="item.status == 4">{{ status_values[item.status] }}</span>
                              <span class="text-purple-900" v-else>{{ status_values[item.status] }}</span>
                            </td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ item.datatime_update_str }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6" v-if="paginas_totales>1">
        <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
          <div class="mx-auto items-center text-center">
            <nav class="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
              <span @click="listar_estatus_tarea(pagina_actual - 1);">
                <button class="text-xs relative z-10 inline-flex items-center bg-white border border-gray-400 px-4 py-2 font-semibold text-indigo-600 focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 ml-1" v-if="pagina_actual - 1 > 0"> Anterior</button>
                <span v-else></span>
              </span>
              <span v-for="pagina in pagination" :key="pagina" @click="listar_estatus_tarea(pagina + 1);">
                <button class="text-xs relative z-10 inline-flex items-center bg-indigo-500 border border-indigo-500 px-4 py-2 font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" v-if="pagina_actual == pagina + 1">{{ pagina + 1 }}</button>
                <button class="text-xs relative z-10 inline-flex items-center bg-white border border-gray-400 px-4 py-2 font-semibold text-indigo-600 focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"  v-else>{{ pagina + 1 }}</button>
              </span>

              <span @click="listar_estatus_tarea(pagina_actual + 1);">
              <button class=" text-xs relative z-10 inline-flex items-center bg-white border border-gray-400 px-4 py-2 font-semibold text-indigo-600 focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" v-if="pagina_actual < paginas_totales"> Siguiente</button>
              <span v-else></span>
              </span>
            </nav>
          </div>
        </div>
      </div>
      <div class=" w-full m-auto">
        <button type="button" class="my-8 justify-end sm:flex-auto rounded-md  bg-white px-3 py-1 text-right text-sm font-semibold text-purple-400 shadow-sm hover:bg-purple-800 hover:text-white" @click="project_tasks(proyecto_id_seleccionado)">Ver tareas del proyecto</button>
      </div>
    </div>
  </template>
  
  <script>
  import HistorialServices from '@/services/history_status/history_status'
  import TasksServices from '@/services/tasks/tasks'


  export default {
    name: 'StatusHistory',
  
    data() {
      return {
        status_values: {
          1: 'Pendiente',
          2: 'En revisión',
          3: 'En desarrollo',
          4: 'En pruebas',
          5: 'Terminado'
        },
        proyecto_id_seleccionado: '',
        task_id: '',
        tarea: '',
        list_tasks: [],
        list_status: [],
        pagination: [],
        paginas_totales: 0,
        pagina_actual: 1,
        nombre_buscar: '',
        }
      },

    mounted() {
        this.proyecto_id_seleccionado = this.$route.params.id_proyecto;
        this.id_task = this.$route.params.id_tarea;
        this.listar_tareas(this.pagina_actual)
        this.listar_estatus_tarea(this.pagina_actual)
      },
    methods: {
      listar_tareas () {
        TasksServices.tareasListado({
            options:'list_total',
            id_project: this.proyecto_id_seleccionado, 
          }).then(response => {
          this.list_tasks =  response.data.listado
          this.tarea = this.list_tasks.find(item => item.id == this.id_task)
        })
      },
      listar_estatus_tarea (pagina) {
        this.pagina_actual = pagina
        HistorialServices.estatusHistorial({
          options:'list',
          id_task: this.id_task,
          page: pagina,
        }).then(response => {
          this.list_status =  response.data.listado
          this.paginas_totales = response.data.paginacion.length
          this.pagination = [...Array(this.paginas_totales).keys()];
        })
      },
      change_status(id_tarea){
        HistorialServices.cambiarEstatus({
          options:'new',
          id_task: id_tarea,
          user_id: localStorage.getItem('user_id'),
          status: event.target.value,
        })
        .then(response => {
              this.$swal({
                title: "¡Buen trabajo!",
                text: response.data.message,
                icon: "success",});
                this.list_tasks = []
                this.listar_estatus_tarea(1)
                this.status_values = {
                  1: 'Pendiente',
                  2: 'En revisión',
                  3: 'En desarrollo',
                  4: 'En pruebas',
                  5: 'Terminado'
                }
            })
      },
      project_tasks (id_project) {
        this.$router.push({
          name: "TareaProyecto",
          params:{
            id: id_project,
          }
        });
      },
    },
    props: {
      msg: String
    }
  }

  const statuses = { Completed: 'text-green-400 bg-green-400/10', Error: 'text-rose-400 bg-rose-400/10' }
  

  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  
  </style>
  