export function permissions (state) {
    return state.permissions;
}

export function userType (state) {
    return state.user.type;
}

export function userId (state) {
    return state.user.id;
}