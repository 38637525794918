<template class="bg-stone-400">
  <div id="editor">
    <div class="flex flex-col justify-items-start space-y-10 ">
      <h1 class="font-bold text-3xl">Crear template</h1>
      <div class="relative w-2/5 ">
        <label class="absolute font-bold -top-2 left-2 inline-block bg-white px-1 text-xs text-gray-900">Nombre del template</label>
        <input type="text" name="nombre(s)" class="block w-full mb-5 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="Ingrese el nombre que tendrá el template" v-model="nameTemplate"/>
      </div>
    </div>
    <div class="flex flex-row justify-items-center bg-gray-300 py-5">
      <button class="cursor-pointer ml-5" @click="addElement('box')"> <i class="fa fa-plus text-indigo-600"></i> Div</button>
      <button class="cursor-pointer ml-20" @click="modalTabla()"> <i class="fa fa-plus text-indigo-600"></i> Tabla</button>
      <button class="cursor-pointer ml-20" @click="modalImagen()"> <i class="fa fa-plus text-indigo-600"></i> Imagen</button>
      <button class="cursor-pointer ml-20" @click="addElement('boxImage')"> <i class="fa fa-plus text-indigo-600"></i> Imagen dinámica</button>
    </div>
    <div class="bg-gray-300" v-if="selectedElement !== null">
      <h3 :class="[selectedType === 'table' || selectedType === 'image'?'':'mb-5']">Propiedades del elemento seleccionado:</h3>
      <label class="mt-5" v-if="selectedType === 'box'">
        Contenido:
        <input class="w-11/12" type="text" v-model="elements[selectedElement].content" placeholder="Inserte el texto aquí"/>
      </label>
      <label class="mt-5" v-if="selectedType === 'boxImage'">
        Contenido:
        <input class="w-11/12" type="text" v-model="elements[selectedElement].content" placeholder="Inserte el idetificador que tendra la URL de la imagen"/>
      </label>
    </div>
    <div class="flex space-y-10 bg-gray-300 w-full">
      <div v-if="selectedElement !== null" class="controls flex w-full justify-items-center">
        <div class="flex flex-col w-full">
          <div class="flex flex-row text-center items-center mb-5">
            <div :class="[elements[selectedElement].style.textAlign === 'left'? 'shadow-blue bg-gray-300' : '', '','block w-6 h-6 mx-3 cursor-pointer text-center border border-slate-300 font-black hover:bg-gray-300']" @click="elements[selectedElement].style.textAlign = 'left'" v-if="elements[selectedElement].type === 'box'">
              <i class="fa fa-align-left text-black" ></i>
            </div>
            <div :class="[elements[selectedElement].style.textAlign === 'center'? 'shadow-blue bg-gray-300' : '', '','block w-6 h-6 mx-3 cursor-pointer text-center border border-slate-300 font-black hover:bg-gray-300']" @click="elements[selectedElement].style.textAlign = 'center'" v-if="elements[selectedElement].type === 'box'">
              <i class="fa fa-align-center text-black" ></i>
            </div>
            <div :class="[elements[selectedElement].style.textAlign === 'right'? 'shadow-blue bg-gray-300' : '', '','block w-6 h-6 mx-3 cursor-pointer text-center border border-slate-300 font-black hover:bg-gray-300']" @click="elements[selectedElement].style.textAlign = 'right'" v-if="elements[selectedElement].type === 'box'">
              <i class="fa fa-align-right text-black" ></i>
            </div>
            <div class="block w-6 h-6 mx-3 mr-8 cursor-pointer text-center" v-if="elements[selectedElement].type === 'box'">
              <input type="color" id="cellColorText" v-model="elements[selectedElement].style.color" class="block w-8">
            </div>
            <div v-if="elements[selectedElement].type === 'box' || elements[selectedElement].type === 'image' || elements[selectedElement].type === 'boxImage'" class="mr-5">
              Ancho:
              <input type="number" class="w-20" v-model.number="selectedElementWidth" /> px
            </div>
            <div v-if="elements[selectedElement].type === 'box' || elements[selectedElement].type === 'image' || elements[selectedElement].type === 'boxImage'" class="mr-5">
              Alto:
              <input type="number" class="w-20" v-model.number="selectedElementHeight" /> px
            </div>
            <div v-if="elements[selectedElement].type === 'box'" class="mr-5">
              Color de Fondo:
              <input type="color" v-model="elements[selectedElement].style.backgroundColor" class="w-8"/>
            </div>
            <div v-if="elements[selectedElement].type === 'box'  || elements[selectedElement].type === 'boxImage'" class="mr-5">
              Color de borde:
              <input type="color" v-model="elements[selectedElement].style.borderColor" class="w-8">
            </div>
          </div>
          <div class="flex flex-row text-center">
            <label v-if="elements[selectedElement].type === 'box'" class="mr-5">
              Peso de Fuente:
              <input type="number" class="w-20" v-model.number="selectedElementFontWeight" />
            </label>
            <label v-if="elements[selectedElement].type === 'box'" class="mr-5">
              Tamaño de Fuente:
              <input type="number" class="w-20" v-model.number="selectedElementFontSize" /> pt
            </label>
            <label class="mr-5">
              z-index:
              <input type="number" class="w-20" v-model="elements[selectedElement].style.zIndex">
            </label>
            <button @click="deleteElement(selectedElement)" class="inline-flex justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600" style="margin: 1em;">Eliminar elemento</button>
          </div>
        </div>
        <div></div>
      </div>
      <div v-else class="ml-5 my-5 text-center w-full">
        <h3>Agrega o selecciona un elemento del canvas para poder personalizarlo</h3>
      </div>
    </div>
    <div class="w-full flex justify-center">
      <div id="canvas" class="canvas mt-5">
        <div class="grid-layer"></div>
        <div
          v-for="(element, index) in elements"
          :key="element.id"
          :id="element.id"
          class="draggable resizable"
          :style= "element.style"
          @click="selectElement(index)"
          @dblclick="element.type === 'table' ? editTable(element, index) : null"
        >
          <div v-if="element.type === 'box'">
            {{ element.content }}
          </div>
          <div v-if="element.type === 'table'">
            <table :style="[element.style,  element.styleTable, 'position: block']">
              <thead>
                <tr>
                  <th v-for="(col, colIndex) in element.headersTable" :key="colIndex" :style="col.style">{{ col.content }}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td v-for="(col, colIndex) in element.rowsTable" :key="colIndex" :style="col.style">{{ col.content }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="element.type === 'image'">
            <img :src="element.url" alt="Imagen subida">
          </div>
          <div v-if="element.type === 'boxImage'">
            {{ element.content }}
          </div>
        </div>
      </div>
    </div>
    <div class="relative float-label-input">
      <button type="button" class="text-sm font-semibold leading-6 text-gray-900 px-1.5 py-1.5 rounded-md border border-gray-950" style="margin: 1em;" @click="handleCancel()">Cancelar</button>
      <button @click="exportTemplate()" class="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" style="margin: 1em;">Generar plantilla</button>
    </div>
  </div>
  <TransitionRoot as="template" :show="abrirModal">
    <Dialog as="div" class="relative z-40" @close="cerrarModal()">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
              <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>
        <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 max-2xl:items-center">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <DialogPanel class="flex flex-col justify-between relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8  sm:max-w-4xl sm:p-6" style=" width: 80vw; height: 80vh; transform: translateX(10vw)!important;">
                  <div class="flex flex-col " style="gap: 1rem">
                    <div class="grid justify-items-end ">
                      <div class="text-center text-xs cursor-pointer hover:bg-indigo-500 hover:text-white w-5" @click="cerrarModal()">X</div>
                    </div>
                    <div>
                      <div class="mt-3 text-center sm:mt-5">
                        <DialogTitle as="h3" class="text-3xl font-semibold leading-9 text-gray-900">Insertar Tabla</DialogTitle>
                      </div>
                    </div>
                    <div class="w-full" style="padding: 0 10px; max-height: 20vh; height: 79vh;">
                      <div class="px-4 sm:px-6 lg:px-8 flex justify-center w-full flex-col  flex-1 relative ">
                        <div class="flex flex-col">
                          <div class="mt-5">
                              <label>
                                Establece el número de columnas que tendrá la tabla:
                                  <input type="number" class="w-15" v-model.number="numColumnas" @input="customSettings()" />
                              </label>
                          </div>
                          <div v-if="selectedCell !== null" class="mt-5">
                            <h3 class="font-bold text-lg text-center">Personalizar celda seleccionada</h3>
                            <div class="flex flex-row items-center mt-3">
                              <label for="cellText" class="block w-1/5 font-bold">Texto:</label>
                              <input type="text" id="cellText" v-model="selectedCell.content" class="block w-3/4" placeholder="Inserte el texto de la celda">
                            </div>
                            <div class="flex flex-row items-center mt-3">
                              <span for="cellText" class="block w-1/5 font-bold text-white">Texto:</span>
                              <div class="flex flex-row items-center ml-3">
                                <div :class="[selectedCell.style.fontWeight === 'bold'? 'shadow-blue bg-gray-300' : '', '','mx-3 block w-6 h-6 cursor-pointer text-center border border-slate-300 font-black hover:bg-gray-300']" @click="toggleFontWeight(selectedCell)">
                                  <span>N</span>
                                </div>
                                <div :class="[selectedCell.style.textAlign === 'left'? 'shadow-blue bg-gray-300' : '', '','block w-6 h-6 mx-3 cursor-pointer text-center border border-slate-300 font-black hover:bg-gray-300']" @click="selectedCell.style.textAlign = 'left'">
                                  <i class="fa fa-align-left text-black" ></i>
                                </div>
                                <div :class="[selectedCell.style.textAlign === 'center'? 'shadow-blue bg-gray-300' : '', '','block w-6 h-6 mx-3 cursor-pointer text-center border border-slate-300 font-black hover:bg-gray-300']" @click="selectedCell.style.textAlign = 'center'">
                                  <i class="fa fa-align-center text-black" ></i>
                                </div>
                                <div :class="[selectedCell.style.textAlign === 'right'? 'shadow-blue bg-gray-300' : '', '','block w-6 h-6 mx-3 cursor-pointer text-center border border-slate-300 font-black hover:bg-gray-300']" @click="selectedCell.style.textAlign = 'right'">
                                  <i class="fa fa-align-right text-black" ></i>
                                </div>
                                <div class="block w-6 h-6 mx-3 cursor-pointer text-center">
                                  <input type="color" id="cellColorText" v-model="selectedCell.style.color" class="block w-8">
                                </div>
                                <div class="flex mx-3 cursor-pointer text-center">
                                  <select class="block w-15" v-model="selectedCell.style.fontSize">
                                    <option class="relative" v-for="size in fontSizes" :key="size" :value="size">
                                      {{ size }}
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div class="flex flex-row items-center mt-3" v-if="selectedCell.tipo === 'header'">
                              <label for="cellBackground" class="block w-1/5 font-bold">Color de fondo:</label>
                              <input type="color" id="cellBackground" v-model="selectedCell.style.backgroundColor" class="block w-8 mr-5">
                              <label for="cellBorder" class="block w-1/5 font-bold">Color de borde:</label>
                              <input type="color" id="cellBorder" v-model="selectedCell.style.borderColor" class="block w-8">
                              <label for="cellWidth" class="mx-5">Ancho:</label>
                              <input type="number" id="cellWidth" class="w-20" v-model.number="selectedCellWidth" /> px
                              <label for="cellHeight" class="mx-5">Alto:</label>
                              <input type="number" id="cellHeight" class="w-20" v-model.number="selectedCellHeight" /> px
                            </div>
                          </div>
                          <div v-else class="mt-5">
                            <p>Seleccione un celda para poder personalizarla</p>
                          </div>
                          <table class="mt-5 border-collapse border border-slate-500">
                            <thead>
                                <tr class="">
                                  <th v-for="(col, colIndex) in encabezados" :key="'head-' + colIndex" :style="encabezados[colIndex].style" :class="[selectedCell === col ? 'shadow-blue' : '']" @click="selectedCell = col">
                                    {{ col.content }}
                                  </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="">
                                  <td v-for="(col, colIndex) in columns" :key="'body-' + colIndex" :style="columns[colIndex].style" :class="[selectedCell === col ? 'shadow-blue' : '']" @click="selectedCell = col" >
                                    {{ col.content }}
                                  </td>
                                </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex justify-center mt-5">
                      <button @click="cerrarModal()" class="mt-5 inline-flex justify-center rounded-md bg-whitw px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-50" style="margin: 1em;" >Cancelar</button>
                      <button v-if="isEdition" @click="updateTable()" class="mt-5 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" style="margin: 1em;">Actualizar tabla</button>
                      <button v-else @click="addElement('table'), abrirModal = false" class="mt-5 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" style="margin: 1em;">Generar tabla</button>
                  </div>
                </DialogPanel>
            </TransitionChild>
            </div>
        </div>
    </Dialog>
  </TransitionRoot>
  <TransitionRoot as="template" :show="abrirModalImagen">
    <Dialog as="div" class="relative z-40" @close="cerrarModal()">
      <TransitionChild as="div" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
              <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>
        <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 max-2xl:items-center">
            <TransitionChild as="div" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <DialogPanel class="flex flex-col justify-between relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8  sm:max-w-4xl sm:p-6" style=" width: 80vw; height: 80vh; transform: translateX(10vw)!important;">
                <div class="flex flex-col " style="gap: 1rem">
                  <div class="grid justify-items-end ">
                    <div class="text-center text-xs cursor-pointer hover:bg-indigo-500 hover:text-white w-5" @click="cerrarModal()">X</div>
                  </div>
                  <div>
                    <div class="mt-3 text-center sm:mt-5">
                        <DialogTitle as="h3" class="text-3xl font-semibold leading-9 text-gray-900">Insertar Imagen</DialogTitle>
                    </div>
                  </div>
                  <div class="w-full" style="padding: 0 10px; max-height: 20vh; height: 79vh;">
                    <div class="px-4 sm:px-6 lg:px-8 flex justify-center w-full flex-col  flex-1 relative ">
                      <div class="px-4 sm:px-6 lg:px-8 flex justify-center w-full flex-col space-y-4 flex-1 relative mt-2 ">
                        <div>
                          <div class="col-span-full">
                            <label for="cover-photo" class="block text-sm font-medium leading-6 text-gray-400">Seleccione la imagen que desea insertar</label>
                            <div class="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                              <div class="text-center">
                                <img src="@/assets/image_icon.png" alt="Icono_imagen" width="50%" class="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                                <div class="mt-4 flex text-sm leading-6 text-gray-600">
                                  <label for="file-upload" class="relative cursor-pointer rounded-md bg-white text-sm font-semibold text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-300 focus-within:ring-offset-2 hover:text-indigo-600">
                                    <span class="pl-1"></span>
                                    <input type="file" id="file" ref="myFiles" class="custom-file-input" >
                                  </label>
                                </div>
                                <p class="text-xs leading-5 text-gray-600">Da click sobre el botón o arrastra y suelta los archivos sobre él</p>
                                <p class="text-xs leading-5 text-gray-600">PNG, JPG, GIF de hasta 10MB</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex justify-center mt-5">
                  <button @click="cerrarModal()" class="mt-5 inline-flex justify-center rounded-md bg-whitw px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-50" style="margin: 1em;" >Cancelar</button>
                  <button @click="cargarImagen()" class="mt-5 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" style="margin: 1em;">Agregar imagen</button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import templatesServices from '@/services/templates/template'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'

export default {
  components: {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  },
  data() {
    return {
      elements: [],
      selectedElement: null,
      nameTemplate: '',
      nextElementId: 1,
      abrirModal:false,
      abrirModalImagen: false,
      numColumnas: 2,
      encabezados:[],
      columns: [],
      selectedCell: null,
      fontSizes: ['9px', '10px', '11px', '12px', '13px', '14px','15px', '16px', '18px', '20px','22px', '24px', '28px', '32px'],
      isEdition: false,
      indexTablaEdit: 0,
      urlLastImage: '',
      autoSaveInterval: null
    };
  },
  mounted() {
    this.startAutoSave()
    this.restoreCanvas()
    this.$nextTick(() => {
      this.elements.forEach((element) => {
        this.initializeDraggableAndResizable(element.id);
      });
    })
  },
  beforeDestroy() {
    // Desvincula el evento resize cuando se destruya el componente
    $(window).off('resize');
  },
  computed: {
    selectedType() {
      return this.selectedElement !== null ? this.elements[this.selectedElement].type : null;
    },
    selectedElementWidth: {
      get() {
        return this.selectedElement !== null
          ? parseInt(this.elements[this.selectedElement].style.width, 10)
          : 0;
      },
      set(value) {
        if (this.selectedElement !== null) {
          this.elements[this.selectedElement].style.width = `${value}px`
        }
      },
    },
    selectedElementHeight: {
      get() {
        return this.selectedElement !== null
          ? parseInt(this.elements[this.selectedElement].style.height, 10)
          : 0;
      },
      set(value) {
        if (this.selectedElement !== null) {
          this.elements[this.selectedElement].style.height = `${value}px`
        }
      },
    },
    selectedCellWidth: {
      get() {
        return this.selectedCell !== null
          ? parseInt(this.selectedCell.style.width, 10)
          : 0;
      },
      set(value) {
        if (this.selectedCell !== null) {
          this.selectedCell.style.width = `${value}px`
        }
      },
    },
    selectedCellHeight: {
      get() {
        return this.selectedCell !== null
          ? parseInt(this.selectedCell.style.height, 10)
          : 0;
      },
      set(value) {
        if (this.selectedCell !== null) {
          this.selectedCell.style.height = `${value}px`
        }
      },
    },
    selectedElementFontSize: {
      get() {
        return this.selectedElement !== null
          ? parseInt(this.elements[this.selectedElement].style.fontSize, 10)
          : 14; // valor predeterminado
      },
      set(value) {
        if (this.selectedElement !== null) {
          this.elements[this.selectedElement].style.fontSize = `${value}px`
        }
      },
    },
    selectedElementFontWeight: {
      get() {
        return this.selectedElement !== null
          ? parseInt(this.elements[this.selectedElement].style.fontWeight, 10)
          : 400; // valor predeterminado
      },
      set(value) {
        if (this.selectedElement !== null) {
          this.elements[this.selectedElement].style.fontWeight = value
        }
      },
    }
  },
  methods: {
    addElement(type) {
      const id = `element-${this.nextElementId}`;
      this.nextElementId += 1;
      const baseStyleBox = {
        width: '500px',
        height: '30px',
        top: '0px',
        left: '0px',
        borderColor: '#000000',
        borderWidth: '1px',
        borderStyle: 'solid',
        backgroundColor: '#ffffff',
        position: 'absolute',
        fontWeight: 400,
        fontSize: '14px',
        zIndex: 10,
        textAlign: 'left',
        color: '#000000'
      };
      const baseStyleBoxImage = {
        width: '50px',
        height: '50px',
        position: 'absolute',
        top: '0px',
        left: '0px',
        borderColor: '#000000',
        borderWidth: '1px',
        borderStyle: 'solid',
        backgroundColor: 'transparent',
        fontWeight: 400,
        fontSize: '14px',
        zIndex: 10,
        textAlign: 'left',
        color: '#000000'
      };
      const baseStyleContenedor = {
        width: '500px',
        height: '30px',
        top: '0px',
        left: '0px',
        border: 'none',
        backgroundColor: 'transparent',
        position: 'absolute',
        zIndex: 10,
      };
      const baseStyleTable = {
        // width: '70vw',
        // height: '20px',
        // top: '0px',
        // left: '0px',
        borderCollapse: 'collapse',
        borderWidth: '1px',
        borderColor: '#808080',
        borderStyle: 'solid',
        // zIndex: 10
      };
      const baseStyleImage = {
        width: '150px',
        height: '150px',
        top: '0px',
        left: '0px',
        position: 'absolute',
        zIndex: 10,
        border: 'none'
      };
      if (type === 'box') {
        this.elements.push({
          id,
          type,
          content: '',
          style: baseStyleBox,
        });
      } else if (type === 'table') {
        this.elements.push({
          id,
          type,
          numCols: this.numColumnas,
          headersTable: [...this.encabezados],
          rowsTable: [...this.columns],
          style: baseStyleContenedor,
          styleTable: baseStyleTable
        });
      } else if (type === 'image') {
        this.elements.push({
          id,
          type,
          url: this.urlLastImage,
          style: baseStyleContenedor,
        });
      } else if (type === 'boxImage') {
        this.elements.push({
          id,
          type,
          content: '',
          style: baseStyleBoxImage,
        });
      }
      this.$nextTick(() => {
        this.initializeDraggableAndResizable(id);
      });
    },
    deleteElement(index) {
      if (this.elements[index].url) {
        this.eliminarImagen(this.elements[index].url)
      }
      this.elements.splice(index, 1);
      this.selectedElement = null;
      this.elements = [...this.elements];

      this.$nextTick(() => {
        this.elements.forEach(element => {
          this.initializeDraggableAndResizable(element.id);
        });
      });
    },
    initializeDraggableAndResizable(id) {
      const $element = $(`#${id}`);

      // Destroy any previous instances to avoid conflicts
      if ($element.hasClass('ui-draggable')) {
        $element.draggable('destroy');
      }
      if ($element.hasClass('ui-resizable')) {
        $element.resizable('destroy');
      }

      $element.draggable({
        //containment: '#canvas', // Limitar el arrastre dentro del canvas
        stop: (event, ui) => {
          const element = this.elements.find(e => e.id === id);
          if (element) {
            console.log('top',ui.position.top, 'left', ui.position.left, 'evento',ui)
            element.style.top = `${ui.position.top}px`;
            element.style.left = `${ui.position.left}px`;
          }
          // console.log(this.elements, 'listado de elementos, arrastre')
        }
      });
      $element.resizable({
        //containment: '#canvas', // Limitar el redimensionamiento dentro del canvas
        stop: (event, ui) => {
          const element = this.elements.find(e => e.id === id);
          if (element) {
            console.log('width',ui.size.width, 'height', ui.size.height, 'evento',ui)
            element.style.width = `${ui.size.width}px`;
            element.style.height = `${ui.size.height}px`;
          }
          // console.log(this.elements, 'listado de elementos, redimensionamiento')
        }
      });
    },
    handleCancel() {
      localStorage.removeItem('elements');
      this.$router.push('/templates_list');
    },
    buildStyleString(style) {
      return Object.entries(style).map(([key, value]) => {
        // Convertir camelCase a kebab-case
        const kebabKey = key.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
        return `${kebabKey}: ${value};`;
      }).join(' ');
    },
    selecionarCelda(celda) {
      this.selectedCell = celda
    },
    customSettings() {
      const numCols = this.numColumnas;
      while (this.encabezados.length < numCols) {
        this.encabezados.push({
          tipo: 'header',
          content: '',
          style: {
            color: '#000000',
            backgroundColor: '#ffffff',
            borderColor: '#000000',
            borderWidth: '1px',
            borderStyle: 'solid',
            fontWeight: 'normal',
            height: '24px',
            width: '40px',
            textAlign: 'center',
            fontSize: '13px',
            wordWrap: 'break-word',
          }
        });
      }
      while (this.encabezados.length > numCols) {
          this.encabezados.pop();
      }
      while (this.columns.length < numCols) {
        this.columns.push({
          tipo: 'row',
          content: '{{ variable }}',
          style: {
            color: '#000000',
            backgroundColor: '#ffffff',
            borderColor: '#000000',
            borderWidth: '1px',
            borderStyle: 'solid',
            fontWeight: 'normal',
            height: '20px',
            textAlign: 'center',
            fontSize: '14px',
            wordWrap: 'break-word',
          }
        });
      }
      while (this.columns.length > numCols) {
          this.columns.pop();
      }
    },
    exportTemplate() {
      if (!this.nameTemplate) {
        this.$swal({
          icon: 'warning',
          title: 'Ingrese un nombre para la plantilla',
          timer: 3000
        });
        return;
      }
      localStorage.setItem('elements', JSON.stringify(this.elements )) // guardar información del canvas en el localStorage
      clearInterval(this.autoSaveInterval); // detener el guardado automático en el localStorage
      const today = new Date();
      var num_tabla = 0
      let canvasContent = this.elements.map(element => {
        if (element.type === 'box') {
          return `
    <div id="${element.id}" style="${this.buildStyleString(element.style)}">
      ${element.content}
    </div>
          `;
        } else if (element.type === 'table') {
          num_tabla += 1
          return `
    <div style="${this.buildStyleString(element.style)}">
    <table id="${element.id}" style="${this.buildStyleString({width: element.style.width, height: element.style.height})} ${this.buildStyleString(element.styleTable)} position: block !important">
      <thead>
        <tr>
          ${element.headersTable.map(col =>
          `<th style="${this.buildStyleString(col.style)}">
            ${col.content}
          </th>
          `).join('')}
        </tr>
      </thead>
      <tbody>
        {% for item in tabla${num_tabla} %}
        <tr>
          ${element.rowsTable.map(col =>
          `<td style="${this.buildStyleString(col.style)}">
            {{ item.${col.content} }}
          </td>
          `).join('')}
        </tr>
        {% endfor %}
      </tbody>
    </table>
    </div>`;
        }else if (element.type === 'image') {
          return `
    <div style="${this.buildStyleString(element.style)}" >
      <img id="${element.id}" src="${element.url}" alt="Imagen subida" style="${this.buildStyleString({width: element.style.width, height: element.style.height})}">
    </div>`;
        }
        else if (element.type === 'boxImage') {
          return `
      <div style="${this.buildStyleString(element.style)}" >
        <img id="${element.id}" src="${element.content}" alt="imagendinamica" style="${this.buildStyleString({width: element.style.width, height: element.style.height})}">
      </div>`;
          }
        return '';
      }).join('');

      const html_string = `
<html>
  <head>
    <meta charset="UTF-8" />
    <title>${this.nameTemplate}</title>
  </head>
  <body>
    <div id="canvas">
      ${canvasContent}
    </div>
  </body>
</html>
      `;

      templatesServices.createTemplate({
        options: 'new',
        html: html_string,
        nombre: this.nameTemplate,
        fechacreacion: today.toISOString(),
        templete: JSON.stringify(this.elements)
      }).then(response => {
        console.log('El template fue creado exitosamente');
        localStorage.removeItem('elements');
        this.$swal({
          icon: 'success',
          title: 'template creado correctamente',
          timer: 2000
        });
        this.$router.push({ name: 'templates', params: {} })
      });
    },
    selectElement(index) {
      this.selectedElement = index;
    },
    updateTable() {
      this.elements[this.indexTablaEdit].numCols = this.numColumnas
      this.elements[this.indexTablaEdit].headersTable = [...this.encabezados]
      this.elements[this.indexTablaEdit].rowsTable = [...this.columns]
      this.abrirModal = false
    },
    modalTabla(){
      this.abrirModal = !this.abrirModal
      if (this.abrirModal) {
        this.encabezados = []
        this.columns = []
        this.customSettings()
      }
    },
    editTable(selectedTable, indice){
      this.abrirModal = true
      this.isEdition = true
      this.indexTablaEdit = indice
      this.numColumnas = selectedTable.numCols
      this.encabezados = selectedTable.headersTable
      this.columns = selectedTable.rowsTable
      this.customSettings()
    },
    toggleFontWeight(celda) {
        celda.style.fontWeight = celda.style.fontWeight === 'bold' ? 'normal' : 'bold';
    },
    modalImagen(){
      this.abrirModalImagen = true
    },
    cargarImagen() {
      this.files = this.$refs.myFiles.files
      console.log(this.files);
      const data = new FormData();
      data.append('file' , this.files[0]);
      templatesServices.uploadImage(data)
      .then(response => {
        console.log(response)
        this.urlLastImage = 'http://127.0.0.1:8000/' + response.data.url
        this.addElement('image')
        this.abrirModalImagen = false
      })
    },
    eliminarImagen(urlImagen) {
      templatesServices.deleteImage({
        url: urlImagen
      })
      .then(response => {
        console.log(response)
      })
    },
    cerrarModal(){
      if (this.abrirModal) {
        this.abrirModal = false
      } else {
        if (this.abrirModalImagen) {
          this.abrirModalImagen = false
          this.eliminarImagen(this.urlLastImage)
        }
      }
    },
    startAutoSave() {
      this.autoSaveInterval = setInterval(() => {
        if (this.elements.length > 0) {
          localStorage.setItem('elements', JSON.stringify(this.elements ))
        }
      }, 5 * 60 * 1000); // 15 minutos en milisegundos
    },
    restoreCanvas() {
      const savedElements = localStorage.getItem('elements');
      if (savedElements) {
        this.$swal({
          icon: 'success',
          title: 'Se ha recuperado el avance de una plantilla ¿Deseas usarlo?',
          showCancelButton: true,
          confirmButtonText: 'Continuar edición',
          cancelButtonText: 'No, comenzar uno nuevo',
        }).then((result) => {
          if (result.isConfirmed) {
            this.elements = JSON.parse(savedElements);
          } else {
            this.elements = []
          }
        });
      }
    },
  }
};
</script>


<style>
  .canvas {
    width: 1030px;
    height: 500px;
    border: 1px solid #ccc;
    position: relative;
    overflow: auto;
    white-space: nowrap; /* Evita el desbordamiento en elementos inline */
  }
  .grid-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 1001px;
    height: 1401px;
    background-size: 20px 20px;
    background-image: linear-gradient(to right, #e0e0e0 1px, transparent 1px), linear-gradient(to bottom, #e0e0e0 1px, transparent 1px);
    z-index: 1;
    pointer-events: none;
  }
  .draggable .resizable{
    position: absolute;
    background-color: #ffffff;
    border: 1px solid #000000;
    width: auto;
    height: auto;
  }
  /* .resizable {
    resize: both;
    overflow: auto;
  } */
  /* .resizable {
    position: absolute;
    background-color: #ffffff;
    border: 1px solid #000000;
  } */
  .controls {
    margin-top: 20px;
  }
  .controls label {
    display: block;
    margin-bottom: 10px;
  }
</style>