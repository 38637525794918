import { createApp } from 'vue';
import App from './App.vue';
import router from './router/index.js'
import './index.css';

import store from './store/index.js'

import DropdownMenu from 'v-dropdown-menu';
import VueSweetalert2 from 'vue-sweetalert2';
import Vuelidate from '@vuelidate/core';
import 'sweetalert2/dist/sweetalert2.min.css'; // If you don't need the styles, do not connect
import Cookies from 'js-cookie';

import jQuery from 'jquery';
import 'jquery-ui/ui/widgets/draggable';
import 'jquery-ui/ui/widgets/resizable';
import 'jquery-ui/themes/base/all.css';

window.$ = window.jQuery = jQuery;

createApp(App).use(router).use(Vuelidate).use(Cookies).use(DropdownMenu).use(VueSweetalert2).use(store).mount('#app');