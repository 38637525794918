import { axiosInstance } from '@/boot/axios';

export default {
    crearCliente (data) {
        return axiosInstance.post('/create_account', data)
    },
    listarClientes (data) {
            return axiosInstance.post('/companies_projects-account', data)
    },
    deleteCliente (data) {
        return axiosInstance.post('/delete_account', data)
    },
    editarCliente (data) {
        return axiosInstance.post('/info_accounts', data)
    },
    guardarCliente (data) {
        return axiosInstance.post('/edit_accounts', data)
    }
}