<template>
  <div class="flex flex-col items-center space-y-10 ">
    <h1 class="font-bold text-3xl">Crear nuevo cliente</h1>
    <h2 class="font-semibold"></h2>
    <div class="relative w-2/5">
      <label for="name" class="absolute font-bold -top-2 left-2 inline-block bg-white px-1 text-xs text-gray-900">Nombre(s)</label>
      <input type="text" name="nombre(s)" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" require v-model="clientes.nombre"/>
    </div>
    <div class="relative w-2/5">
      <label for="name" class="absolute font-bold -top-2 left-2 inline-block bg-white px-1 text-xs text-gray-900">Apellido(s)</label>
      <input type="text" name="apellido(s)" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="clientes.apellido"/>
    </div>
    <div class="relative float-label-input w-2/5">
        <label for="name" class="absolute font-bold -top-2 left-2 inline-block bg-white px-1 text-xs text-gray-900">Seleccione una Empresa</label>
          <select id="company" name="company" autocomplete="company-name"  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="clientes.empresaId" @change="listadoProyectos">
          <option v-for="item in lista_companias" :value= "item.id">{{ item.name }}</option>
          </select>
    </div>
    <div class="relative float-label-input w-2/5">
      <label for="name" class="absolute font-bold -top-2 left-2 inline-block bg-white px-1 text-xs text-gray-900">Seleccione un proyecto</label>
      <select class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" multiple v-model="clientes.proyectos">
      <option v-for="item in list_projects" :value="item.id">{{ item.name }}</option></select>
    </div>
    <div class="relative w-2/5">
        <label for="email" class="absolute font-bold -top-2 left-2 inline-block bg-white px-1 text-xs text-gray-900">Correo</label>
        <input type="mail" name="email" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="clientes.email" placeholder=" you@example.com"/>
    </div>
    <div class="flex flex-row items-center justify-center space-x-4 w-full">
      <div class="relative w-1/5">
      <label for="password" class="absolute font-bold -top-2 left-2 inline-block bg-white px-1 text-xs text-gray-900">Contraseña</label>
        <input type="password" name="password" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="clientes.contrasena" placeholder="8 caracteres minimo"/>
    </div>
    <div class="relative w-1/5">
      <label for="password" class="absolute font-bold -top-2 left-2 inline-block bg-white px-1 text-xs text-gray-900">Repetir Contraseña</label>
        <input type="password" name="password2" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="clientes.repeatcontrasena" placeholder="8 caracteres minimo"/>
    </div>
    </div>
    <div class="relative float-label-input">
      <button type="button" class="text-sm font-semibold leading-6 text-gray-900 px-1.5 py-1.5 rounded-md border border-gray-950" style="margin: 1em;"><router-link to="/clientes">Cancelar</router-link><router-view /></button>
      <button type="submit" @click="guardarcliente" class="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" style="margin: 1em;">Guardar Cliente</button>
    </div>
  </div>
</template>
<script>
import ClientesServices from '@/services/clientes/Clientes'
import CompaniesServices from '@/services/companies/companies'
export default {
    name: 'CrearClientes',
    data (){
        return{

            lista_companias: [],
            list_projects:[],
            clientes: {
                nombre: '',
                apellido:'',
                email:'',
                contrasena: '',
                repeatcontrasena:'',
                empresaId: '',
                proyectos:''
            }
        }
    },
    mounted() {
      this.listaCompanias()
      
    },
    methods: {
        listaCompanias () {
            CompaniesServices.empresasListado({
                options:'list_total', 
            }).then(response => {
                this.lista_companias =  response.data.listado
        })
      },
      listadoProyectos (){
        const database = {
          id_companies: JSON.stringify([this.clientes.empresaId]),
        }
       CompaniesServices.listProjectsCompany(database)
        .then((data) =>{
          console.log(data)
        this.list_projects = data.data.listado
        })
      },
      guardarcliente(){
        if (this.clientes.nombre === '' && this.clientes.apellido === '' && this.clientes.email === '' && this.clientes.contrasena === '' && this.clientes.repeatcontrasena === '') {
          this.$swal(
              'Ups',
              'Es necesario llenar los campos',
              'warning'
          ) 
          return  
        }
          if (this.clientes.nombre === '' || this.clientes.nombre === null){
            this.$swal(
              'Ups',
              'Ingresa los apellidos',
              'warning'
            )
            return
        }
        if (this.clientes.apellido === '' || this.clientes.apellido === null){
            this.$swal(
              'Ups',
              'Debes agregar el apellido completo',
              'warning'
            )
            return
        }
        if (this.clientes.empresaId === '' || this.clientes.empresaId === null){
            this.$swal(
              'Ups',
              'Debes seleccionar una empresa',
              'warning'
            )
            return
        }
        if (this.clientes.email === '' || this.clientes.email === null){
            this.$swal(
              'Ups',
              'Ingresa un correo',
              'warning'
            )
            return
        }
        if (this.clientes.contrasena === '' || this.clientes.contrasena === null){
            this.$swal(
              'Ups',
              'Agrega una contraseña (8 caracteres mínimo)',
              'warning'
            )
            return
        }
        if (this.clientes.repeatcontrasena === '' || this.clientes.repeatcontrasena === null){
            this.$swal(
              'Ups',
              'Repite la contraseña (8 caracteres mínimo)',
              'warning'
            )
            return
        }
        if  (this.clientes.contrasena !== this.clientes.repeatcontrasena){
          this.$swal (
            'Ups',
            'Las contraseñas no son iguales',
            'warning'
          )
          return
        }
        const datasend = {
            username: this.clientes.email,
            email: this.clientes.email,
            name: this.clientes.nombre,
            lastname: this.clientes.apellido,
            password: this.clientes.contrasena,
            type: 2,
            id_companies: JSON.stringify([this.clientes.empresaId]),
            id_projects: JSON.stringify(this.clientes.proyectos),
          }
          ClientesServices.crearCliente(datasend).then((data)=>{
            this.$swal(
              'Perfecto',
              'Se ha registrado correctamente el cliente',
              'success'
              
            )
            this.$router.push('/clientes')
          })
      },
    
}
}
</script> 