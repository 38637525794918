import { axiosInstance } from '@/boot/axios';

export default {
    crearObservacion (data) {
        return axiosInstance.post('remark/crud', data);
    },
    observacionesListado(data) {
        return axiosInstance.post('remark/crud', data);
    },
    eliminarObservacion(data) {
        return axiosInstance.post('remark/crud', data);
    },
    infoObservacion(data) {
        return axiosInstance.post('remark/crud', data);
    },
    editarObservacion(data) {
        return axiosInstance.post('remark/crud', data);
    },
}