<template>
    <div>
      <form v-on:submit.prevent= "edit_task();">
        <div class="space-y-12 sm:space-y-16">
          <div>
            <h1 class="text-center text-2xl font-semibold leading-7 text-gray-900">Editar archivo adjunto </h1>
            <p class="w-1/3 m-auto p-1 text-left text-sm leading-1 text-gray-600">Adjunto correspondiente a la tarea: {{ adjunto.nombre_tarea }}.</p>
            <div class="container w-1/3 m-auto p-3 rounded">
              
              <div class="col-span-full">
                <label for="cover-photo" class="block text-sm font-medium leading-6 text-gray-400">Archivo adjunto</label>
                <div class="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                  <div class="text-center">
                    <img src="@/assets/image_icon.png" alt="Icono_imagen" width="50%" class="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                    <div class="mt-4 flex text-sm leading-6 text-gray-600">
                      <label for="file-upload" class="relative cursor-pointer rounded-md bg-white text-sm font-semibold text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-300 focus-within:ring-offset-2 hover:text-indigo-600">
                        <span class="pl-1"></span>
                        <input type="file" id="file" ref="myFiles" class="custom-file-input" >         
                      </label>
                      
                    </div>
                    <p class="text-xs leading-5 text-gray-600">Da click sobre el botón o arrastra y suelta los archivos sobre él</p>
                    <p class="text-xs leading-5 text-gray-600">PNG, JPG, GIF de hasta 10MB</p>
                  </div>
                </div>
              </div>

              <div class="relative float-label-input">
                <button type="button" class="text-sm font-semibold leading-6 text-gray-900 px-1.5 py-1.5 rounded-md border border-gray-950" style="margin: 1em;" v-if="!proyecto_id_seleccionado"  @click="cancel();">Cancelar</button>
                <button type="button" class="text-sm font-semibold leading-6 text-gray-900 px-1.5 py-1.5 rounded-md border border-gray-950" style="margin: 1em;" v-else @click="listar_adjunts(task_id);">Cancelar</button>
                <button type="submit" class="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" style="margin: 1em;" @click="previewFiles()">Editar Adjunto</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </template>
  
  <script>
  import AdjuntsServices from '@/services/adjunts/adjunts'

  export default {
    name: 'EditTask',
    data() {
      return {
        proyecto_id_seleccionado: '',
        task_id: '',
        id_adjunt: '',
        adjunto: {
          url: '',
          hora_fecha: '',
          autor: '',
          nombre_tarea: '',
        },
        files: [],
      }
    },
    mounted() {
      this.task_id = this.$route.params.id_tarea;
      this.proyecto_id_seleccionado = this.$route.params.id_proyecto;
      this.id_adjunt = this.$route.params.id_adjunto;
      AdjuntsServices.infoAdjunto({
        options:'info',
        id_adj: this.id_adjunt, 
      })
      .then(response => {
      this.adjunto.url = response.data.url
      this.adjunto.hora_fecha = response.data.datatime_adj
      this.adjunto.autor = response.data.username_author
      this.adjunto.nombre_tarea = response.data.name_task   
      })
      },
    methods: {
      listar_adjunts (id_task) {
        this.$router.push({
          name: "AdjuntosTarea",
          params:{
            id_tarea: id_task,
            id_proyecto: this.proyecto_id_seleccionado,
          }
        });
      },
      cancel () {
        this.$router.push({
          name: "tasks",
        });
      },
      previewFiles() {
        this.files = this.$refs.myFiles.files
        console.log(this.files);
        const data = new FormData();
        data.append('options' , 'update');
        data.append('archivo' , this.files[0]);
        data.append('id_adj' , this.id_adjunt);
        console.log(data);
        AdjuntsServices.editarAdjunto(data)
        .then(response => {
          this.$swal({
            title: "¡Buen trabajo!",
            text: response.data.message,
            icon: "success",});
          if(!this.proyecto_id_seleccionado) {
            this.$router.push("/tasks");
          }
          else {
            this.listar_adjunts(this.task_id)
          }
        })
      },
    },
    props: {
      msg: String
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: black;
  }

  .float-label-input {
    margin: 32px 0;     
  }

  .float-label-input:focus-within label, .float-label-input input:not(:placeholder-shown) + label {
    transform: translateY(-1.5rem) scale(0.75);
    background-color: white;
  }
  </style>
  