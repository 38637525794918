<link href="{% src 'css/asesoriasExpres-historial.css' %}" rel="stylesheet"></link>
<template>
    <div>
        <div class="space-y-12 sm:space-y-16">
          <div>
            <h1 class="text-center text-2xl font-semibold leading-7 text-gray-900">Crear Repositorio</h1>
            <p class="w-1/3 my-8 m-auto p-1 text-left text-sm leading-1 text-gray-600">Registra los datos del Repositorio.</p>
            <div class="container w-3/5 m-auto p-3 rounded ajus-responsive">
              <div class="relative float-label-input">
                <input type="text" id="nameproyecto" name="nameproyecto" autocomplete="name" placeholder="" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="repos.proyecto" />
                <label for="nameproyecto" class="absolute top-3 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white px-2 text-grey-darker">Nombre del Proyecto</label>
                <span class="block bg-white w-full focus:outline-none focus:shadow-outline border border-red-500 rounded-md py-1 px-1 appearance-none leading-normal" v-if="submited && !$v.repos.proyecto.required">El campo nombre es obligatorio.</span>
              </div>
              <div class="relative float-label-input">
                <input type="text" id="namerepositorio" name="namerepositorio" autocomplete="name" placeholder="" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="repos.repositorio" />
                <label for="namerepositorio" class="absolute top-3 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white px-2 text-grey-darker">Nombre del Repositorio</label>
                <span class="block bg-white w-full focus:outline-none focus:shadow-outline border border-red-500 rounded-md py-1 px-1 appearance-none leading-normal" v-if="submited && !$v.repos.repositorio.required">El campo nombre es obligatorio.</span>
              </div>
              <div class="relative float-label-input">
                <input type="text" id="tipo" name="tipo" autocomplete="tipo" placeholder="" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="repos.tipo" />
                <label for="tipo" class="absolute top-3 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white px-2 text-grey-darker">Tipo</label>
                <span class="block bg-white w-full focus:outline-none focus:shadow-outline border border-red-500 rounded-md py-1 px-1 appearance-none leading-normal" v-if="submited && !$v.repos.tipo.required">El campo nombre es obligatorio.</span>
              </div>
              <div class="relative float-label-input">
                <input type="text" id="ipremote" name="ipremote" autocomplete="ipremote" placeholder="" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="repos.ips" />
                <label for="ipremote" class="absolute top-3 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white px-2 text-grey-darker">Dirección IP</label>
                <span class="block bg-white w-full focus:outline-none focus:shadow-outline border border-red-500 rounded-md py-1 px-1 appearance-none leading-normal" v-if="submited && !$v.repos.ips.required">El campo ip es obligatorio.</span>
              </div>
              <div class="relative float-label-input">
                <input type="text" id="ruta" name="ruta" autocomplete="ruta" placeholder="" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="repos.ruta" />
                <label for="ruta" class="absolute top-3 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white px-2 text-grey-darker">Ruta</label>
                <span class="block bg-white w-full focus:outline-none focus:shadow-outline border border-red-500 rounded-md py-1 px-1 appearance-none leading-normal" v-if="submited && !$v.repos.ruta.required">El campo ruta es obligatorio.</span>
              </div>
              <div class="relative float-label-input">
                <input type="text" id="actual_log" name="actual_log" autocomplete="actual_log" placeholder="" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="repos.actual_log" />
                <label for="actual_log" class="absolute top-3 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white px-2 text-grey-darker">actual_log</label>
                <span class="block bg-white w-full focus:outline-none focus:shadow-outline border border-red-500 rounded-md py-1 px-1 appearance-none leading-normal" v-if="submited && !$v.repos.actual_log.required">El campo actual_log  es obligatorio.</span>
              </div>
              <div class="relative float-label-input">
                <input type="text" id="lang_log" name="lang_log" autocomplete="lang_log" placeholder="" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="repos.lang_log" />
                <label for="lang_log" class="absolute top-3 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white px-2 text-grey-darker">lang_log</label>
                <span class="block bg-white w-full focus:outline-none focus:shadow-outline border border-red-500 rounded-md py-1 px-1 appearance-none leading-normal" v-if="submited && !$v.repos.lang_log.required">El campo ip es obligatorio.</span>
              </div>
              <div class="relative float-label-input w-full">
                  <label for="autentificador" class="absolute font-bold -top-2 left-2 inline-block bg-white px-1 text-xs text-gray-900">Tipo de Autentificador</label>
                    <select id="autentificador" name="autentificador"  class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="selectedOption" @change="checkOption">
                      <option value="urlkey">urlkey</option>
                      <option value="pass">pass</option>
                    </select>
              </div>
              <div class="relative float-label-input" v-if="showDropzone">
                <h2>urlkey</h2>
                <form style=" color: rgb(209, 209, 209); border-radius:5%; height: 20vh; background: #d1d1d1;" action="/upload" method="post" enctype="multipart/form-data">
                  <input style="margin-top: 4vw;     margin-inline: 36%; width: 30%; background-color:rgb(209 209 209);"type="file" name="fileToUpload" id="fileToUpload">
                  <input type="submit" value="" name="submit">
                </form>
              </div>
              <div class="relative float-label-input" v-if="showimput">
                <input type="text" id="usrssh" name="usrssh" autocomplete="usrssh" placeholder="" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="repos.usr" />
                <label for="usrssh" class="absolute top-3 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white px-2 text-grey-darker">Usuario</label>
              </div>
              <div class="relative float-label-input" v-if="showimput">
                <input type="text" id="comando" name="comando" autocomplete="comando" placeholder="" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="repos.comando" />
                <label for="comando" class="absolute top-3 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white px-2 text-grey-darker">Comando</label>
              </div>
              <div class="relative float-label-input" v-if="showimput">
                <input type="text" id="pass" name="pass" autocomplete="pass" placeholder="" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" v-model="repos.passe" />
                <label for="pass" class="absolute top-3 left-0 text-gray-400 pointer-events-none transition duration-200 ease-in-outbg-white px-2 text-grey-darker">Password</label>
              </div>
              <div class="relative float-label-input">
                <button type="button" class="text-sm font-semibold leading-6 text-gray-900 px-1.5 py-1.5 rounded-md border border-gray-950" style="margin: 1em;"><router-link to="/updateprollect">Cancelar</router-link><router-view /></button>
                <button @click="create_repo()" class="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" style="margin: 1em;">Agregar Proyecto</button>
              </div>
            </div>
          </div>
        </div>
    </div>
  </template>
  
  <script>
  import ProjectsServices from '@/services/projects/projects'
  import CompaniesServices from '@/services/companies/companies'
  import repositoriosServices from '@/services/repositorios/repositorios'
  import { required } from '@vuelidate/validators';
  import useVuelidate from '@vuelidate/core';
  export default {    name: 'CreateProject',
    data() {
      return {
        list_companies: [],
        list_programs:[],
        showimput: false,
        selectedOption: '',
        showDropzone: false,
        submited: false,
        lista_accesos: [],
        repos: {
          options:'new',
          proyecto : '',
          repositorio : '',
          tipo : '',
          estado : '',
          passe : '',
          ips: '',
          usr: '',
          comando: '',
          ruta: '',
          lang_log: '',
          actual_log :'',
          
        },
      }
    },
    mounted() {
      this.listar_empresas()
    },
    setup() {
      return { v$: useVuelidate() };
    },
    methods: {
      checkOption() {
        if (this.selectedOption === 'urlkey') {
          this.showDropzone = true;
          this.showimput = false;
        }
        if(this.selectedOption === 'pass'){
          this.showimput = true;
          this.showDropzone = false;
        }
      },
      listar_empresas () {
        repositoriosServices.empresasListado({
            options:'list_total', 
          }).then(response => {
          this.list_companies =  response.data.listado
        })
      },
      create_repo () {
        this.v$.$touch();
        if (this.v$.$invalid) {
          console.log('Formulario no válido');
          return;
        }
        console.log(this.repos.Proyecto)
        console.log(this.repos.Repositorio)
        console.log(this.repos.Tipo)
        console.log(this.repos.Estado)
        console.log(this.repos.Passe)
        console.log(this.repos.lang_log)
        console.log(this.repos.actual_log)
        console.log(this.repos.ruta)
        repositoriosServices.crearRepo(this.repos)
        .then(response => {
        this.$swal({
           title: "¡Buen trabajo!",
           text: response.data.message,
           showConfirmButton: false,
           timer: 1500,
           icon: "success",});
        this.$router.push("/updateprollect");
        })
      },
    },
    validations: {
    },
    props: {
      msg: String
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  @media ( max-width: 700px){
      .ajus-responsive{
          width: 90vw;

      }
  }
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: black;
  }

  .float-label-input {
    margin: 32px 0;     
  }

  .float-label-input:focus-within label, .float-label-input input:not(:placeholder-shown) + label {
    transform: translateY(-1.5rem) scale(0.75);
    background-color: white;
  }
  .float-label-input select:not(:placeholder-shown) + label {
    transform: translateY(-1.5rem) scale(0.75);
    background-color: white;
  }
  </style>
  @/services/repositorios/repositorios