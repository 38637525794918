import { axiosInstance } from '@/boot/axios';

export function fetchUser({ commit }) {
// Supongamos que haces una llamada a una API para obtener los datos del usuario
    return new Promise((resolve, reject) => {
        // Reemplaza esto con tu llamada real a la API
        setTimeout(() => {
        const user = {
            hash_id: '123',
            id: '456',
            is_admin: true,
            permissions: ['read', 'write'],
            profile: 'profile data',
            type: 2, // Por ejemplo, 2
            username: 'usuarioEjemplo'
        };
        commit('setUser', user);
        resolve(user);
        }, 1000);
    });
}

export function getUserInfo ({dispatch, commit}) {
    return new Promise((resolve, reject) => {
        // Action to fix sesion user information if window browser is refreshed.
        if ( !window.sessionStorage.getItem('sartolux_token') ) {
            dispatch('logout');
            commit('deleteUser');
            reject();
        } else {
            dispatch('sessionInfo')
                .then(() => { resolve() })
                .catch(() => { reject() });
        }
    });
}

export function sessionInfo ({dispatch, commit}) {
    return new Promise( (resolve, reject) => {
        axiosInstance.get('/user/' + window.sessionStorage.getItem('sartolux_hash'))
            .then(response => {
                commit('setUser', response.data.user);
                resolve();
            }).catch(error => {
                dispatch('logout');
                commit('deleteUser');
                reject();
        });
    });
}

export function setSession ({}, session) {
    sessionStorage.setItem('auth_token', session.auth_token);

    axiosInstance.defaults.headers.common = {
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: 'Token ' + sessionStorage.getItem('auth_token')
    };
}

export function setAuth() {
    axiosInstance.defaults.headers.common = {
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        Authorization: sessionStorage.getItem('auth_token')
    };
}

export function logout () {
    sessionStorage.removeItem('auth_token');

    axiosInstance.defaults.headers.common = {
        Authorization: ''
    };
}

export function setUserType({ commit }, userType) {
    commit('setUserType', userType);
}

export function  setUserId({ commit }, userId) {
    commit('setUserId', userId);
}