<template>
    <div>
      <form>
        <div class="space-y-12 sm:space-y-16">
          <div>
            <h1 class="text-center text-2xl font-semibold leading-7 text-gray-900">Crear un adjunto </h1>
            <p class="w-1/3 m-auto p-1 text-left text-sm leading-1 text-gray-600">Registra la información del adjunto a generar.</p>
            <div class="container w-1/3 m-auto p-3 rounded">
              <div class="relative cursor-pointer rounded-md bg-white text-sm font-semibold text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-300 focus-within:ring-offset-2 hover:text-indigo-600">
                <!-- <vue-dropzone 
                  ref="myVueDropzone" 
                  id="dropzone" 
                  :options="dropzoneOptions" 
                  @vdropzone-sending="sendingEvent"
                  @vdropzone-success="uploadSuccess"
                  >
                </vue-dropzone> -->
              </div>


              <div class="relative float-label-input">
                <button type="button" class="text-sm font-semibold leading-6 text-gray-900 px-1.5 py-1.5 rounded-md border border-gray-950" style="margin: 1em;" @click="listar_adjunts(task_id);">Cancelar</button>
                <button type="submit" class="inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" style="margin: 1em;" v-on:click="uploadfile()">Agregar Adjunto</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </template>
  
  <script>
  import AdjuntsServices from '@/services/adjunts/adjunts'
  // import vue2Dropzone from 'vue2-dropzone'
  // import 'vue2-dropzone/dist/vue2Dropzone.min.css'
   
  // Dropzone.options.myDropzone = {
  // init: function() {
  //   this.on("success", function(file, responseText) {
  //     // Handle the responseText here. For example, add the text to the preview element:
  //     file.previewTemplate.appendChild(document.createTextNode(responseText));
  //   });
  // }
  // };
  export default {
    name: 'CreateAdjunt',
    // components: {
    // vueDropzone: vue2Dropzone
    // },
    data() {
      return {
        // dropzoneOptions: {
        //   url: 'http://127.0.0.1:8000/adjunt_task/crud',
        //   thumbnailWidth: 150,
        //   maxFilesize: 300,
        //   autoProcessQueue: false,
        //   uploadMultiple:   false,
        //   dictDefaultMessage: "<p><i class='fa fa-cloud-upload text-gray-700' style='font-size:60px;text-shadow:2px 2px 4px #000000;'></i></p> <span class='text-indigo-400 hover:text-indigo-600'>Arrastra y suelta los archivos aquí</span> <p class='text-xs leading-5 text-gray-600'>PNG, JPG, GIF de hasta 10MB</p> <p class='border border-gray-600 bg-slate-100  text-black p-1 my-4'>Seleccionar archivo</p>",
        // },
        task_id: '',
        proyecto_id_seleccionado: '',
        // files: [],
      }
    },
    mounted() {
      this.task_id = this.$route.params.id_tarea;
      this.proyecto_id_seleccionado = this.$route.params.id_proyecto;
    },
    methods: {
      listar_adjunts (id_task) {
        this.$router.push({
          name: "AdjuntosTarea",
          params:{
            id_tarea: id_task,
            id_proyecto: this.proyecto_id_seleccionado,
          }
        });
      },
      // previewFiles() {
      //   this.files = this.$refs.myFiles.files
      //   console.log(this.files);
      //   const data = new FormData();
      //   //console.log(this.adjunt.archivo);
      //   data.append('options' , 'new');
      //   data.append('archivo' , this.files[0]);
      //   data.append('id_task' , this.task_id);
      //   console.log(data);
      //   AdjuntsServices.crearAdjunto(data)
      //   .then(response => {
      //     this.$swal({
      //       title: "¡Buen trabajo!",
      //       text: response.data.message,
      //       icon: "success",});
      //     if(!this.proyecto_id_seleccionado) {
      //       this.$router.push("/tasks");
      //     }
      //     else {
      //       this.listar_adjunts(this.task_id)
      //     }
      //   })
      // },
      sendingEvent (file, xhr, formData) {
        formData.append('options' , 'new');
        formData.append('archivo' , file);
        formData.append('id_task' , this.task_id);
        formData.append('user_id' , localStorage.getItem('user_id'));
      },
      uploadSuccess (file, response) {
        this.$router.push({
          name: "AdjuntosTarea",
          params:{
            id_tarea: this.id_task,
            id_proyecto: this.proyecto_id_seleccionado,
          }
        });
      },
      uploadfile () {
        this.$swal({
                title: "¡Buen trabajo!",
                text: 'Adjunto creado de manera exitosa',
                icon: "success",});
        this.$refs.myVueDropzone.processQueue()
        setTimeout(() => {
          this.listar_adjunts(this.task_id)
        }, "1000");

      }
    },
    props: {
      msg: String
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  @import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"); 
  .float-label-input {
    margin: 32px 0;     
  }

  .float-label-input:focus-within label, .float-label-input input:not(:placeholder-shown) + label {
    transform: translateY(-1.5rem) scale(0.75);
    background-color: white;
  }
  </style>
  